const _file = 'analyticsEvents';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var analyticsEvents = (function($){
  function register_event(args) {
    var event_args = $.extend({
      hitType: 'event',
      eventCategory: 'Button',
      eventAction: 'Click',
    }, args);

    if (typeof ga != 'undefined') {
      ga('send',            event_args);
      ga('orgTracker.send', event_args);
    }
  }

  function user_event_for($elem, label_postfix) {
    var args = {eventLabel: $elem.text()};

    if ($elem.parents('form').length > 0) {
      args['eventCategory'] = 'Form';
      args['eventAction']   = 'Submit';
    }

    if ($elem.data('ga-event-label')) {
      args['eventLabel']    = $elem.data('ga-event-label');
    }

    if (label_postfix) {
      args['eventLabel']    = args['eventLabel'] + ' ' + label_postfix;
    }

    register_event(args);
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find('.track-ga-event').each(function() {
      var $btn = $(this);
      var $form = $btn.parents('form');

      if ($form.length > 0) {
        $form.submit(function(e) {
          user_event_for($btn, "Attempt");
        });

        $form.on('complete', function(event, xhr, status) {
          if (status == "success") {
            user_event_for($btn, null);
          }
        });
      }
      else {
        $btn.click(function(e) {
          user_event_for($btn, null);
        });
      }
    });

    $(element).find(".external-link").click(function(){
      register_event({
        eventCategory: 'Article',
        eventAction: 'External Link Click',
        eventLabel: $(this).attr("href")
      });
    });
  });
})(jQuery);
