/**
 * Created by StefanS on 2019-Apr-4
 */

const _file = 'GlassFade';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassFade = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find('.fade-in').addClass('active');

    $(window).scroll(function(){
      $('.fade-in-scroll, .fade-up-scroll').each(function(){
        var elementTop     = $(this).offset().top,
            elementBottom  = elementTop + $(this).outerHeight(),
            viewportTop    = $(window).scrollTop(),
            viewportBottom = viewportTop + $(window).height();

        if (elementBottom > viewportTop && elementTop < viewportBottom) {
          $(this).addClass('active');
        }
      });
    });
  });

  // Return API for other modules
  return {
  };
})(jQuery);

export default GlassFade
