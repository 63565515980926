// Create a group of buttons with a common data-btn-group:
//   <button type="button" class="btn btn-default" data-btn-group="foo" data-val="1"></button>
// And an input:
//   <input type="hidden" data-btn-group="foo">
// ... and the buttons will get an "active" class when clicked and will set the input

const _file = 'BtnGroup';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var BtnGroup = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $inputs = $(element).find('input[data-btn-group]');
    $inputs.each(function () {
      var $input = $(this);
      var $wrapper = $('body');
      if ($input.data('wrapper-selector')) {
        $wrapper = $input.parents($input.data('wrapper-selector'));
      }
      var $elems = $wrapper.find('[data-btn-group="' + $input.data('btn-group') + '"]');
      var $btns  = $elems.filter('.btn');
      $btns.click(function (e) {
        e.preventDefault();
        $elems.removeClass('active');
        var val = $(this).data('val');
        $elems.filter('[data-val="' + val + '"]').addClass('active');
        $input.val(val);
        setTimeout(function() {
          $input.trigger('changed');
        }, 150);
      });

      $btns.filter('[data-val="' + $input.val() + '"]').click();
    });
  });

  // Return API for other modules
  return {};
})(jQuery);
