// IMPORTANT: This is the SINGLE instance of jQuery. Please don't require another
//  At most you can move this line to a different place so it is the first line to run

// import jQuery from 'jquery' -> this is external now (outside of webpack)

// TODO: we need a way for extensions to add these in themselves (without multiple jQuery instances)
window.$.mask     = require('jquery-mask-plugin');
window.$.validate = require('jquery-validation');
window.GC = {} // Keeps global functions namespaced

$(function() {
  var skipped_elements = [];

  $(document.body).find('.skip-content-ready').each(function(index, element){
    $('<div class="skipped-content-ready" data-index="' + index + '"></div>').insertAfter($(this));
    skipped_elements[index] = $(this).detach();
  });

  $(document).trigger('content-ready', document.body);

  $(document.body).find('.skipped-content-ready').each(function(){
    $(skipped_elements[$(this).data('index')]).insertBefore($(this));
    $(this).remove();
  });

  $('.skip-content-ready').removeClass('skip-content-ready');
})

// Use this to act on content-ready events
// $(document).on('content-ready', _double_include ? null : function (e, element) {});
