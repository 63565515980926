const _file = 'WordCountAmount';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var WordCountAmount = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $text_field = element.querySelector('.wordcount-works-with-amount');
    var $amount_field = element.querySelector('.wordcount-amount');
    if ($text_field && $amount_field) {
      const BASE_COST = parseFloat($text_field.getAttribute('data-wordcount-min-cost')) || 0;
      const BASE_WORDS = parseFloat($text_field.getAttribute('data-wordcount-min-words')) || 0;
      const COST_PER_WORD = parseFloat($text_field.getAttribute('data-wordcount-cost-per-word')) || 1;

      var $form = $text_field.closest('form');
      $text_field.addEventListener("change", updateAmount);
      $text_field.addEventListener("keyup", updateAmount);

      function updateAmount(e) {
        var extra_words = $text_field.value.replace(/^\s+|\s+$/g,"").split(/\s+/).length - BASE_WORDS;
        if (extra_words < 0) {
          extra_words = 0;
        }
        var amt = BASE_COST + (extra_words * COST_PER_WORD);
        $amount_field.value = amt; // leave in dollars - this is converted into cents in amount-form-part.js
        $amount_field.dispatchEvent(new Event("change"));
      }
    }
  });
})(jQuery);
