const _file = 'parishMembership';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var parishMembership = (function($){

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $('.radio-registration').change(function() {
      if ($(this).prop('checked')) {
        $('#member_currently_registered_thankyou_msg').removeClass('d-none');
      }
    });

    $('#edit_parish_registration').on('form-submit-success', function(e, response, statusText, xhr, element) {
      if (statusText == 'success') {
        if ($('#member_currently_registered').prop('checked') || $('#member_would_like_to_register').prop('checked')) {
          $('#you_are_currently_registered_msg').removeClass('d-none');
          $('#register_at_parish_buttons').addClass('d-none');
        }
      }
    });

    $('#member_religion').change(function() {
      if ($(this).val().match(/^(Catholic|Evangelical|Orthodox)/)) {
        $('.member_baptised').removeClass('d-none');
      } else {
        $('.member_baptised').addClass('d-none');
      }

      if ($(this).val() == "Catholic") {
        $('.member_confirmed').removeClass('d-none');
      } else {
        $('.member_confirmed').addClass('d-none');
      }

      if ($(this).val() == "Other") {
        $('#religion_other_div').removeClass('d-none');
      } else {
        $('#religion_other_div').addClass('d-none');
      }
    });

    $('#member_spouse_attributes_religion').change(function() {
      if ($(this).val().match(/^(Catholic|Evangelical|Orthodox)/)) {
        $('.spouse_baptised').removeClass('d-none');
      } else {
        $('.spouse_baptised').addClass('d-none');
      }

      if ($(this).val() == "Catholic") {
        $('.spouse_confirmed').removeClass('d-none');
      } else {
        $('.spouse_confirmed').addClass('d-none');
      }

      if ($(this).val() == "Other") {
        $('#spouse_religion_other_div').removeClass('d-none');
      } else {
        $('#spouse_religion_other_div').addClass('d-none');
      }
    });

    $('#member_last_name').blur(function() {
      /* Set all family last names to the member's last name
         if they have not yet been set */
      var last_name = $(this).val();

      if ( !$('#member_spouse_attributes_last_name').val() ) {
        $('#member_spouse_attributes_last_name').val(last_name);
      }

      $('.child-last-name').each(function() {
        if ( !$(this).val() ) {
          $(this).val(last_name);
        }
      });
    });

    $('.container').on('cocoon:before-remove', function(event, insertedItem) {
      var confirmation = confirm("Are you sure you want to remove this child?");
      if( confirmation === false ){
        event.preventDefault();
      }
    });
  });
})(jQuery);
