var frequency_divisors = {month: 1, quarter: 3, halfyear: 6, year: 12};

const _file = 'AmountFormPart';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var AmountFormPart = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {

    $(element).find(".quantity, .frequency, .payments, .payments-length-override, .transaction-multiplier").on("keyup change", function(e) {update_amount_totals(null)});

    $(element).find(".frequency").change(function(e) {
      set_payments_to_max($(this).val(), false);
    });

    // Fixing Edge bug
    $(element).find('.transaction-amount-visible').focus(function(){ $(this).addClass('focus') });
    $(element).find('.transaction-amount-visible').blur(function(){ $(this).removeClass('focus') });

    $(element).find(".transaction-amount-visible")
      .keydown(format_transaction_amount)
      .on("keyup change focus", function(e) {update_amount_totals(null)});

    $(element).find(".works-with-transaction-amount").change(function(){
      $(".works-with-transaction-amount").each(function() {
        var dollars_amount = $(this).prop('checked') || $(this).is("select") || $(this).hasClass("wordcount-amount") ? $(this).val() : 0;
        $(this).data('calculated-cents', dollars_amount * 100);
      })
      update_amount_totals($(this));
    }).change();

    var $payments_select = $(element).find('.payments');
    if ($payments_select.length > 0) {
      var campaign_length = parseInt($payments_select.data('campaign-length'));
      $payments_select.find('option').each(function() {
        var $opt = $(this);
        $opt.addClass(Object.keys(frequency_divisors).map(function(k) {
          return (campaign_length / frequency_divisors[k]) >= parseInt($opt.prop('value')) ? k : '';
        }).join(' '));
      });
    }

    update_amount_totals(null);
  });

  var set_payments_to_max = function(frequency, override) {
    var $form     = $(".formbuilder-form"),
        $payments = $form.find(".payments"),
        payments  = Math.floor(parseInt($payments.data('campaign-length')) / (frequency_divisors[frequency] || 1));

    if ($payments.val() > 1 && $payments.val() <= payments && !override) {
      // already in a good range so we won't touch it
      payments = $payments.val();
    }
    else if($payments.val() != payments) {
      $payments.val(payments);
      $payments.change();
    }
    return payments;
  }

  var update_amount_totals = function($trigger_elem) {
    var $form          = $(".formbuilder-form"),
        $works_with    = $form.find(".works-with-transaction-amount"),
        $frequency     = $form.find(".frequency"),
        $payments      = $form.find(".payments"),
        $quantity      = $form.find(".quantity"),
        $item_amount   = $form.find(".item-amount"),
        $cents_field   = $form.find(".transaction-amount"),
        $multiplier    = $form.find(".transaction-multiplier"),
        $dollars_input = $form.find(".transaction-amount-visible");

    var quantity      = $quantity ? $quantity.val() : null,
        multiplier    = $multiplier ? $multiplier.val() : null,
        custom_total  = !$dollars_input.is(".has-custom-total"),
        cents         = 0,
        frequency     = $frequency.is("select, [type='hidden']") ? $frequency.val() : $frequency.find("input:checked").val(),
        frequency     = frequency != "once"                      ? frequency : "",
        payments      = 1,
        options_value = String(cents/100),
        cursor_pos    = $dollars_input.get_cursor_position();

    if (frequency) {
      if ($('.payments-length-override').prop('checked')) {
        payments = set_payments_to_max(frequency, true);
        $('.payments').parents('.form-group').addClass('d-none');
      }
      else {
        payments = $payments.val() > 0 ? $payments.val() : 1;
        $('.payments').parents('.form-group').removeClass('d-none');
        $('.payments option').addClass('d-none');
        $('.payments option.' + frequency).removeClass('d-none');
      }
    }
    else {
      $('.payments').parents('.form-group').addClass('d-none');
    }

    var works_with_parts = {};
    $(".works-with-transaction-amount").each(function(){
      works_with_parts[$(this).attr('name')] = true;
    });

    // select or check the SINGLE 'works with' part (not sure why we need to do this)
    if (works_with_parts.length == 1) {
      if ($works_with.is('select')) {
        $works_with.val(options_value);
        if ($works_with.val() == null) {
          $works_with.val("Other");
        }
      } else if (!$works_with.is(':checkbox')) {
        $form.find(".works-with-transaction-amount").prop("checked", false);

        if (!$form.find(".works-with-transaction-amount[value='" + options_value + "']").length) {
          options_value = "Other";
        }

        $form.find(".works-with-transaction-amount[value='" + options_value + "']").prop("checked", true);
      }
    }

    $form.find(".works-with-transaction-amount").parents("label").removeClass('active');
    $form.find(".works-with-transaction-amount:checked").parents("label").addClass('active');

    // transaction calculation: quantity of an item (e.g. products or tickets)
    if ($item_amount.length > 0 && quantity) {
      cents += Math.round(parseFloat($item_amount.text()) * 100 * quantity);
    }

    // transaction calculation: 'works with' fields that add to the transaction
    $works_with.each(function() {
      cents += $(this).data('calculated-cents') || 0;
    });

    // transaction calculation: multiply total by transaction_multiplier
    if (multiplier) {
      cents = cents * multiplier;
    }

    var triggered_by_works_with = $trigger_elem && $trigger_elem.hasClass('works-with-transaction-amount');

    if ($dollars_input.length > 0) {
      if (triggered_by_works_with) { // a 'works with' element is filling in the visible dollars input field
        var dollars_str = cents ? (cents / 100.0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";

        if (parseInt(dollars_str) == 0) {
          dollars_str = ".00";
          cursor_pos = 0;
        }

        $dollars_input.val(dollars_str);

        // $dollars_input.focus();

        if ($dollars_input.hasClass('focus')) {
          $dollars_input.set_cursor_position(cursor_pos);
        }
      }
      else {
        // Allow selecting text
        if ($dollars_input.get(0).selectionStart !== $dollars_input.get(0).selectionEnd) {
          return true;
        }

        // transaction calculation: override all calculations above because the user has specified a custom donation amount
        cents = $dollars_input.val() ? Math.round(parseFloat($dollars_input.val()) * 100) : 0;
      }
    }

    // transaction calculation: 'custom_total' means we've been calculating the total, convert this to the transaction amount here
    if (custom_total || payments) {
      cents = Math.floor(cents/payments); // ArchSeattle specifically asked to always round partial cents down
    } else if (payments <= 1 && frequency && frequency != "once") {
      payments = -1;
    }

    $cents_field.val(cents);

    show_totals_message({
      amount: cents ? (cents / 100.0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "", // dollars_str but always transaction amount (above it could be the total pledge amount)
      payments: payments,
      frequency: frequency,
      custom_total: custom_total
    });
  }

  var show_totals_message = function(totals) {
    $form = $(".formbuilder-form");

    if (!totals.custom_total) {
      if (totals.payments <= 1) {
        totals.payments  = 0;
        totals.frequency = "once";
      }
      if (totals.amount <= 0) {
        totals.payments  = 0;
        totals.frequency = 0;
      }
    } else {
      totals.payments = 0;
    }

    $form.find('.summary-text .summary-text-frequency').addClass('d-none');
    $form.find('.summary-text [data-frequency="' + totals.frequency + '"]').removeClass('d-none');

    if (!totals.frequency) {
      $form.find('.summary-text [data-frequency="once-simple"]').removeClass('d-none');
    }

    for (var key in totals) {
      if (totals[key]) {
        $form.find("." + key + "-show").each(function() {
          var val = $(this).data('multiplier') ? parseFloat(totals[key]) * parseFloat($(this).data('multiplier')) : totals[key];
          $(this).text(val).parent("." + key + "-wrapper").removeClass('d-none');
          $form.find("." + key + "-description").removeClass('d-none');
        });
      } else {
        $form.find("." + key + "-show").text("").parent("." + key + "-wrapper").addClass('d-none');
        $form.find("." + key + "-description").addClass('d-none');
      }
    }
  }

  var format_transaction_amount = function(e){
    var $field      = $(this),
        value       = $field.val(),
        key         = e.which || e.keyCode,
        char        = String.fromCharCode(key),
        cursor_pos  = $field.get_cursor_position(),
        decimal_pos = value.indexOf(".");

    if ($field.get(0).selectionStart !== $field.get(0).selectionEnd) {
      return true;
    }

    if ([8,46].indexOf(key) >= 0 && decimal_pos + 1 == cursor_pos) {
      $field.set_cursor_position(cursor_pos-1);
      return false;
    } else if (char.match(/[0-9]/)) {
      if (value == 0) {
        $field.val("");
        return true;
      } else if (decimal_pos + 1 == cursor_pos) {
        $field
          .val(value.slice(0, cursor_pos) + value.slice(cursor_pos+1))
          .set_cursor_position(cursor_pos);
      } else if (decimal_pos + 2 == cursor_pos) {
        $field.val(value.slice(0, cursor_pos));
      } else if (decimal_pos + 3 == cursor_pos) {
        return false;
      }
    } else if ([110,190].indexOf(key) >= 0 && cursor_pos == decimal_pos) {
      $field.set_cursor_position(cursor_pos+1);
      return false;
    }
  }

  $.fn.get_cursor_position = function() {
    var input = this.get(0),
        position;

    if (!input) {
      return false;
    }

    if ('selectionStart' in input) {
      position = input.selectionStart;
    } else if (document.selection) {
      var sel = document.selection.createRange();
      var selLen = document.selection.createRange().text.length;
      sel.moveStart('character', -input.value.length);
      position = sel.text.length - selLen;
    }

    return position;
  }

  $.fn.set_cursor_position = function(position) {
    var cursor_pos = $(this).get_cursor_position();
    $(this).get(0).selectionStart = position;
    $(this).get(0).selectionEnd = position;
  }

  // 2020 review/refactor by SS: I don't know what the search by part_id is for here. We should consider getting rid of this method...
  $.fn.multiple_choice_val = function() {
    var $field  = $(this),
        part_id = $field.is("select") ? $field.attr("id") : $field.attr("id").replace("_" + $field.val().replace(" ", "_"), ""),
        $fields = $("[id*='" + part_id + "']"),
        value   = $field.prop("checked") || $field.is("select") ? $field.val() : "";

    if (!$field.is("select")) {
      if (!$field.is(":radio")) {
        value = [];
      }
      $fields.each(function(){
        if ($(this).prop("checked")) {
          if (!$field.is(":radio")) {
            value.push($(this).val());
          } else {
            value = $(this).val();
          }
        }
      });
    }

    return value;
  }

})(jQuery);
