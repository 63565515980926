import CanvasForms     from 'glass/modules/forms'
import {validateAndScroll} from 'glassformvalidations'

const _file = 'StripePaymentForm';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var StripePaymentForm = (function($){
  function setStripeState($form, state_str, note) {
    // console.log(`DEBUG: stripe state '${state_str}' (${note})`);
    $form.data('stripe-state', state_str);
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $form = $(element).find('.payment-form');

    if ($form.length > 0) {
      $form = $form.first();

      var $pubkey_inp = $('#stripe_publishable_key');
      if ($pubkey_inp.length == 0) {
        CanvasForms.insertErrors($form, 'stripe publishable key not set', null);
        return;
      }

      var stripe = Stripe($pubkey_inp.val());
      var elements = stripe.elements();
      var style = {base: {fontSize: '16px', color: "#5a5a5a", "::placeholder": {color: "#999999"}}};
      // var card_elem = elements.create('card', {style: {base: {fontSize: '16px', color: "#32325d" } }});
      // card_elem.mount('#card-element');
      // card_elem.addEventListener('change', function(e) {CanvasForms.insertErrors($form, e.error ? e.error.message : '', null)});
      // stripe.createToken(card_elem);
      var cc_number_elem = elements.create('cardNumber', {style: style});
      cc_number_elem.mount('#cc-number-element');
      cc_number_elem.addEventListener('change', function(e) {
        CanvasForms.insertErrors($form, e.error ? e.error.message : '', null);
      });
      var cc_expiry_elem = elements.create('cardExpiry', {style: style});
      cc_expiry_elem.mount('#cc-expiry-element');
      cc_expiry_elem.addEventListener('change', function(e) {CanvasForms.insertErrors($form, e.error ? e.error.message : '', null)});
      var cc_cvc_elem = elements.create('cardCvc', {style: style});
      cc_cvc_elem.mount('#cc-cvc-element');
      cc_cvc_elem.addEventListener('change', function(e) {CanvasForms.insertErrors($form, e.error ? e.error.message : '', null)});

      setStripeState($form, "init", "1. init")
      $form.data("preprocess-pending", true);

      setTimeout(function() {
        $form.off('form-before-submit'); // TODO: work out a better api for validations so we don't have to cancel the event like this
      });

      $form.submit(function(e) {
        /****** States ******
         * search for "setStripeState" to see descriptions... here's the flow:
         * init -> authorizing -> ready-to-process -> processing
         * init -> send cc to stripe, get token back -> fall through to forms.js (to send to rails) -> wait for response
         */

        // This is here within the `submit()` to ensure that the other js has had a chance to set on-complete-callback
        if (!$form.data('hooked-to-complete-callback')) {
          $form.data('hooked-to-complete-callback', true); // only hook in here once
          $form.data('orig-on-complete-callback', $form.data('on-complete-callback'));
          $form.data('on-complete-callback', function(result) {
            setStripeState($form, "init", "5. rails submit complete, reset to possibly try again");
            $form.data("preprocess-pending", true);
            var orig_callback = $form.data('orig-on-complete-callback');
            if (orig_callback) {
              return orig_callback(result);
            }
            else {
              return true;
            }
          });
        }

        validateAndScroll($(this)); // resets preprocess-pending
        if ($form.data("preprocess-pending")) {
          setStripeState($form, "init", "1. back to init because js validation failed");
          return false;
        }
        $form.data("preprocess-pending", true); // validation set to false (passed) but we still have work to do here

        if ($form.data('stripe-state') == "ready-to-process") {
          setStripeState($form, "processing", "4. submit to rails server");
          return true;
        }

        if ($form.data('stripe-state') != "init") {
          // only in init state will we attempt to authorize
          return false;
        }

        e.preventDefault();

        setStripeState($form, "authorizing", "2. submit cc to stripe");

        // Disable the submit button to prevent repeated clicks
        CanvasForms.disableSubmit($form);

        // var target_elem = card_elem;
        var target_elem = cc_number_elem;
        // TODO: add all address fields into here
        // https://stripe.com/docs/stripe-js/reference#stripe-create-token for reference
        var card_meta = {
          name: $form.find('#cc-name').val()
        }
        stripe.createToken(target_elem, card_meta).then(function(result) {
          if (result.error) {
            setStripeState($form, "init", "3b. error. reset")
            CanvasForms.insertErrors($form, result.error.message);
          } else {
            var stripe_token = result.token;
            CanvasForms.insertErrors($form, '');
            $form.find('[name="stripeToken"]').remove();
            $form.append($('<input type="hidden" name="stripeToken" />').val(stripe_token.id));
            setStripeState($form, "ready-to-process", "3a. got stripe token, re 'submit'")
            $form.data("preprocess-pending", false);
            CanvasForms.disableSubmit($form);
            $form.submit();
          }
        });

        return false;
      });
    }
  });

  // Return API for other modules
  return {};
})(jQuery);