import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import imagesLoaded from 'canvas/imagesloaded' //https://imagesloaded.desandro.com/v3/

const _file = 'GlassPhotoswipe';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassPhotoswipe = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    if ($(element).find('.glass-edit img').length > 0 && $('.pswp').length > 0) {
      var imgLoad = imagesLoaded(element);

      imgLoad.on('always', function() { // wait for all images to be loaded/confirmed broken before initing the gallery
        imgLoad.images.forEach(function(loadingImg, index) {
          var imgEl = $(loadingImg.img)

          if (loadingImg.isLoaded === false || !$(imgEl).hasClass('inline-editable-image') || !hasGallery($(imgEl)) ) {
            return true; // continue; don't init photoswipe on this element
          }

          if (!$(imgEl).parent().is('a')) {
            $(imgEl).wrap(_imgLinkContainerStr($(imgEl)));
          }

          $(imgEl).parents('.glass-edit').addClass('pswp-images-wrapper')
        });

        var options = {
          // Gallery options
          maxSpreadZoom: 1,
          // UI options
          zoomEl: false,
          fullscreenEl: false,
          shareEl: false,
          counterEl: false,
          tapToClose: true,
          clickToCloseNonZoomable: true
        };

        initPhotoSwipeFromDOMJquery($(element).find('.pswp-images-wrapper'), options);
      });
    }
  });

  function _imgLinkContainerStr(imgEl) {
    imgEl = imgEl[0];
    return `<a class="pswp-img-link" href="${imgEl.src}" data-size="${imgEl.naturalWidth}x${imgEl.naturalHeight}"></a>`;
  }

  var gallery_count = 0;
  var galleries = [];

  /*
    https://gist.github.com/kshnurov/8b175b8798d4a907e47b
  */
  var initPhotoSwipeFromDOMJquery = function($galleryWrappers, _options) {

    var init = function($this){
        $this.each(function(n, gallery){
            var i = gallery_count++;

            galleries.push({
                id: i,
                items: []
            });

            $(gallery).find('.pswp-img-link').each(function(k, link) {
                var $link = $(link),
                    size = $link.data('size') ? $link.data('size').split('x') : "1200x1200".split('x');


                if (size.length != 2){
                    throw SyntaxError("Missing data-size attribute.");
                }
                $link.data('gallery-id',i+1);
                $link.data('photo-id', k);

                var item = {
                    src: link.href,
                    msrc: link.children[0].getAttribute('src'),
                    w: parseInt(size[0],10),
                    h: parseInt(size[1],10),
                    title: $link.data('title'),
                    el: link
                }

                galleries[i].items.push(item);

            });

            $(gallery).off('click').on('click', '.pswp-img-link', function(e){
                e.preventDefault();
                var gid = $(this).data('gallery-id'),
                    pid = $(this).data('photo-id');
                openGallery(gid,pid);
            });

        });
    }

    var openGallery = function(gid,pid){
        var pswpElement = document.querySelectorAll('.pswp')[0],
            items = galleries[gid-1].items,
            options = {
                index: pid,
                galleryUID: gid,
                getThumbBoundsFn: function(index) {
                    var thumbnail = items[index].el.children[0],
                        pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                        rect = thumbnail.getBoundingClientRect();

                    return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
                }
            };
        $.extend(options,_options);
        var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    }


    // initialize
    init($galleryWrappers);

    // Open gallery if coming from a URL
    // this doesn't work because it is called multiple times, need to call it once from js ready
    // if (window.location.hash.includes('&gid') && $('.pswp-img-link').length > 0) {
    //   var $img_link = $('.pswp-img-link').first();
    //   openGallery($img_link.data('gallery-id'), $img_link.data('photo-id'));
    // }
  };


  function hasGallery($elem) {
    // globalDefault == off
    var defaultSetting = $elem.closest('.glass-edit.glass-edit-html').hasClass('pswp-default-on');
    // // globalDefault == on
    // var defaultSetting = !$elem.closest('.glass-edit.glass-edit-html').hasClass('pswp-default-off');

    if (!$elem.hasClass('pswp-gallery-on') && !$elem.hasClass('pswp-gallery-off')) {
      var hasLightbox = defaultSetting;
    } else {
      var hasLightbox = $elem.hasClass('pswp-gallery-on');
    }

    return hasLightbox;
  }

  // Return API for other modules
  return {
    hasGallery: hasGallery
  };
})(jQuery);

export default GlassPhotoswipe
