import CanvasForms     from 'glass/modules/forms'
import WatchForChanges from 'glass/modules/watchforchanges'

/**
 * Created by StefanS on 2014-Dec-23
 */

$.ajaxPrefilter(function( options ) {
  if (['?', '&'].includes(options.url.slice(-1))) {
    options.url = options.url.slice(0, -1)
  }
});

const _file = 'GlassSearch';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassSearch = (function ($){
  var watcher;
  var search_btn_selector = '#refinery-search-btn, .search-btn';
  var xhr_searching;

  $(document).on('content-ready', _double_include ? null : function (e, element) {

    // Temporary... the on show event should work below... bootstrap bug?
    $(element).find('.search-toggle').click(function() {
      var $search_field = $('.search-modal .search-input');

      if (!$(this).is(".collapsed")) {
        $search_field.focus();
      }
      else {
        $search_field.blur();
      }
    });

    // $(element).find('.search-modal.collapse').on('show.bs.collapse', function() {
    //   $(this).find('input.search-input').focus();
    // });

    $(element).find('.search-form').each(function() {
      var $search_form = $(this);
      if (!$search_form.is('form')) {
        // I don't know where the form .parent() is used, but it's how I found this code [SS]
        $search_form = $search_form.find('form').length > 0 ? $search_form.find('form') : $search_form.parent();
      }

      $search_form.find('input#search').on('change focus blur keyup', function(){
        if ($(this).val().length > 0) {
          $(this).parents('.search-form').find('#refinery-search-clear-btn').fadeIn();
        } else {
          $(this).parents('.search-form').find('#refinery-search-clear-btn').fadeOut();
        }
      });

      $search_form.find('#refinery-search-clear-btn').click(function(){
        var $search_field = $(this).parents('.search-form').find('input#search');
        $search_field.val('');
        do_search($search_field);
        $search_field.focus();
        $(this).fadeOut();
      });

      $search_form.find('input[data-btn-group="search-scope"]').on('changed', function(e) {
        var last_val = $(this).data('last-val') || "";
        if (last_val != $(this).val()) {
          do_search($(this), true);
        }
        $(this).data('last-val', $(this).val());
      });

      $search_form.find('.search-scope-filter').click(function() {
        var last_val = $(this).data('last-val') || false;
        if (last_val != $(this).prop('checked')) {
          do_search($(this), true);
        }
        $(this).data('last-val', $(this).prop('checked'));
      });

      // Create a watcher (watch for onchange & onkeypress events)
      watcher = new WatchForChanges.Watcher({
        'onkeypress' : $search_form.find('input#search'),
        'onkeyup'    : $search_form.find('input#search'),
        'callback'   : do_search,
        'delay'      : 700,
        'maxdelay'   : 5000
      });

      var $error_div = '<div id="errorExplanation" class="errorExplanation text-center">Sorry, no results found</div>';

      $search_form.ajaxForm({
        beforeSend: function(xhr) {
          xhr_searching = xhr;
          xhr.setRequestHeader('Accept', 'text/html');
        },
        complete: function(xhr, status) {
          $search_form.trigger('search-complete', [xhr, status]);
          var $btnContainer = $search_form.find(search_btn_selector);

          watcher.resume();

          var $previousError = $('#errorExplanation');

          if($previousError.length > 0){
            $previousError.remove();
          }

          if (status !== 'success') {
            if(xhr.responseJSON && xhr.responseJSON.message !== undefined){
              // insert the error div into the page if there is a message returned from the server.
              $search_form.prepend([
                '<div id="errorExplanation" class="errorExplanation text-center">',
                xhr.responseJSON.message,
                '</div>'
              ].join(''));
            }
            return;
          }
          xhr.done(function(data) {
            $btnContainer.removeClass('searching');

            var callback = $btnContainer.data('callback');
            if (callback) {
              callback(data);
            }
            else {
              var $content = $(data).find('.sortable_list, .content-index-container').first();
              if ($content.length > 0) {
                $('#errorExplanation').remove();

                CanvasForms.replaceContent($('.sortable_list, .content-index-container').first(), $content);
              }
              else {
                $search_form.prepend($error_div);
              }
            }
          });
        }
      });

      $search_form.find(search_btn_selector).click(function(e){
        e.preventDefault();
        do_search($(this));
      });

      $search_form.attr('autocomplete', 'off');
    });
  });

  function do_search($elem, force = false, include_url_query_params = true) {
    var $form = $elem.parents('form');
    if ($form.find('input#search').val() != $form.data('last-search') || force) {
      if (xhr_searching) {
        xhr_searching.abort();
      }
      var $search_btn = $form.find(search_btn_selector);
      var callback = $search_btn.data('callback-on-trigger');
      if (callback) {
        callback();
      }
      $form.data('last-search', $form.find('input#search').val());
      $search_btn.addClass('searching');
      $form.submit();
      var url = $form.attr('action');
      if (include_url_query_params) {
        url += (url.includes('?') ? '&' : '?') + $form.serialize();
      }
      window.history.replaceState(null, null, url);
      watcher.pause();
    }
  }

  // Return API for other modules
  return {
    perform_search: do_search
  };
})(jQuery);

export default GlassSearch
