// How to scroll to elements on a page:
//  a) Use `GlassScroll.scrollTo('#some-id');`
//  b) Set data-scroll-to="#some-id" on a button or <a>
//     - the href= will be ignored if the target element exists
//  c) Optionally set data-scroll-offset="150" on the target element or link/button element

const _file = 'GlassScroll';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassScroll = (function ($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $things = $(element).find('[data-scroll-to]');
    $things.each(function() {
      var $target = $($(this).data('scroll-to'));
      if ($target.length > 0) {
        $(this).click(function(e) {
          e.preventDefault();
          scrollTo($target.first(), $target.data('scroll-offset') || $(this).data('scroll-offset'));
        })
      }
    });
  });

  function scrollTo(target_selector, top_offset, callback) {
    var $target = typeof target_selector == 'string' ? $(target_selector) : target_selector;
    top_offset  = typeof top_offset  !== 'undefined' && top_offset != null ? parseInt(top_offset) : 0;
    var cb      = typeof callback    !== 'undefined' ? callback : function() {};
    // maybe this is better? stop double call of callback?
    // var cb      = function() { if (callback) { calback(); callback = null; } };

    if ($target.length > 0) {
      var destval = $target.offset().top - top_offset;
      var speed = Math.floor(Math.abs(destval - $(window).scrollTop()) / 200) * 100; // rounded down 100
      if (speed < 800) {
        speed = Math.floor(400 + (speed / 2));
      }
      if (speed < 2000) {
        $('html, body').animate({
          scrollTop: destval
        }, speed, cb);
      }
      else {
        var $form = $target.parent();
        $form.animate({
          opacity: 0
        }, 500, function() {
          $('html, body').animate({ scrollTop: destval }, 0);
          $form.animate({ opacity: 1 }, 500, cb);
        });
      }
    }
  }

  // Return API for other modules
  return {
    scrollTo: scrollTo
  };
})(jQuery);

export default GlassScroll