const _file = 'PageNotFound';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var PageNotFound = (function($){
  $(document).on('content-ready', _double_include ? null : function () {
    var scroll_elements = {
      'hills': 0.1,
      'cathedral': -0.006
    };

    if ($('.page-not-found').length) {
      //blink_stars('.star');
      //slow_scroll(scroll_elements);

      $(window).scroll(function(){
        slow_scroll(scroll_elements);
      });
    }
  });
})(jQuery);

var blink_stars = function(selector) {
  var $ = jQuery,
      star_count = 80,
      star = $('.star');

  // Multiply stars and place through screen
  for (var i=0; i<star_count; i++) {
    $(star)
      .css({
        top: (Math.floor(Math.random() * 101)) + '%',
        left: (Math.floor(Math.random() * 101)) + '%'
      })
      .clone()
      .appendTo($(star).parent());
  }

  // Set blink interval
  $(selector).each(function(i, el){
    setInterval(function(){
      $(el).css({opacity: 0});
      setTimeout(function() {
        $(el).css({opacity: 1});
      }, 500);
    }, (Math.floor(Math.random() * 6000) + 2000));
  });
};

var slow_scroll = function(options) {
  var $ = jQuery;
  for (var key in options) {
    $('#' + key).css({'margin-top': (($('#' + key).offset().top - $(window).scrollTop()) * options[key]) + 'px'});
  }
};