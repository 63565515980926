"use strict";

var _HeroEditor = require("./HeroEditor");

var _reactOnRails = _interopRequireDefault(require("react-on-rails"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_reactOnRails.default.register({
  HeroEditor: _HeroEditor.HeroEditor
});