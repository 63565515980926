import tus from 'tus-js-client'
const _file = 'CloudflareVideo';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var CloudflareVideo = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $('#cf_input').change(function(e) {
      var video = e.target.files[0]
      if (window.confirm("Are you sure you want to upload: " + video['name'])) {
        var upload = new tus.Upload(video, {
          endpoint: "https://api.cloudflare.com/client/v4/zones/" + window.cf_zone + "/media",
          retryDelays: [0, 1000, 3000, 5000],
          metadata: {
            filename: video["name"]
          },
          headers: {
            "X-Auth-Key": window.cf_key,
            "X-Auth-Email": window.cf_email
          },
          onError: function(error) {
            console.log("Failed because: " + error)
          },
          onProgress: function(bytesUploaded, bytesTotal) {
            var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
            console.log(bytesUploaded, bytesTotal, percentage + "%")
          },
          onSuccess: function() {
            console.log("Download %s from %s", upload.file.name, upload.url);
            $('[name="item[featured_video_url]"]').val("" + upload.url);
          }
        })
        upload.start()
      } else {
        $('#cf_input')[0].value = null;
      }
    });
  });
})(jQuery);
