const _file = 'tilmaFrontendMinistries';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var tilmaFrontendMinistries = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $btns = $(element).find('.ministry-filtering-btns button');
    $btns.click(function(e) {
      var $cur_btn = $(this);
      $btns.removeClass('active');
      $cur_btn.addClass('active');
      var filter = $cur_btn.data('filter');
      var hidden_class = 'd-none-important';
      if (filter == 'all') {
        $('.item-card').removeClass(hidden_class);
      }
      else {
        $('.item-card').addClass(hidden_class);
      }
      $.each(filter.split(','), function(i, val) {
        $('.item-card.' + val).removeClass(hidden_class);
      });
      var filter_exclude = $cur_btn.data('filter-exclude');
      if (filter_exclude) {
        $.each(filter_exclude.split(','), function(i, val) {
          $('.item-card.' + val).addClass(hidden_class);
        });
      }
    });

    var tab_selector = '#ministry-tabs a.nav-link';
    var $tabs = $(element).find(tab_selector);
    if ($tabs.length > 1) {
      var hash = document.location.hash;
      // Using a suffix in a similar way a prefix is used here: https://stackoverflow.com/a/10787789
      //   to fix a scroll issue. But we're using the inverse of the SO example to keep "-tab" out of the url.
      var suffix = "-tab";
      if (hash) {
        $this_tab = $('#ministry-tabs a[href="' + hash + suffix + '"]');
        $this_tab.tab('show');
      } else {
        $this_tab = $('#ministry-tabs a.active');
      }
      swap_follow_btns($this_tab);

      // Change hash for page-reload
      $(tab_selector).on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash.replace(suffix, "");
        swap_follow_btns($(this));
      });
    }

    function swap_follow_btns($tab) {
      $('.btn-follow').addClass('d-none');
      $('.btn-follow button.follow-ministry[data-ministry-id="' + $tab.data('ministry-tab-id') + '"]').closest('.btn-follow').removeClass('d-none');
    }

    $('.follow-signin').click(function() {
      // TODO: set a cookie of the ministry/topic they want to follow
      window.location.href = '/admin/users/login';
    });

    $('.follow-ministry').click(function() {
      $.ajax({
        context: $(this),
        url: '/member/follow_ministry',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSend: function(xhr) {
          $(this).data('orig-btn-text', $(this).html());
          $(this).html('<i class="active inline xs loader"></i>' + $(this).html());
          $(this).attr('disabled', 'disabled');
        },
        type: 'POST',
        data: {"ministry_id" : $(this).data('ministry-id')},
        success: function(data) {
          $(this).attr('disabled', false);
          $(this).html($(this).data('orig-btn-text'));
          $(this).closest('.btn-follow').addClass('active');
        },
        error: function(data) {
          $(this).html($(this).data('orig-btn-text'));
          $(this).attr('disabled', false);
        }
      });
    });

    $('.unfollow-ministry').click(function() {
      $.ajax({
        context: $(this),
        url: '/member/unfollow_ministry',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSend: function(xhr) {
          $(this).data('orig-btn-text', $(this).html());
          $(this).html('<i class="active inline xs loader"></i>' + $(this).html());
          $(this).attr('disabled', 'disabled');
        },
        type: 'POST',
        data: {"ministry_id" : $(this).data('ministry-id')},
        success: function(data) {
          $(this).attr('disabled', false);
          $(this).html($(this).data('orig-btn-text'));
          $(this).closest('.btn-follow').removeClass('active');
        },
        error: function(data) {
          $(this).html($(this).data('orig-btn-text'));
          $(this).attr('disabled', false);
        }
      });
    });
  });
})(jQuery);
