const _file = 'DismissibleCallToActions';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var DismissibleCallToActions = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    // page_partials/snippet
    if ($(element).find('.snippet-call-to-action').length > 0) {
      var snippet_cookie_name = 'hide_cta_snippet';

      $(window).scroll(function(){
        if ($(window).scrollTop() > 500 && !getCookie(snippet_cookie_name)) {
          $(element).find('.snippet-call-to-action').fadeIn();
        }
      });

      $(element).find('.snippet-call-to-action-close').click(function(){
        setCookie(snippet_cookie_name, true, 30);
        $(element).find('.snippet-call-to-action').fadeOut();
      });
    }

    // subscribe cta
    if ($(element).find('.subscribe-call-to-action').length > 0) {
      var is_subscriber_cookie_name = 'subscriber_guid';
      var hide_subscriber_cookie_name = 'hide_subscriber_cta';

      if (
        !getCookie(is_subscriber_cookie_name)
        && !getCookie(hide_subscriber_cookie_name)
      ) {
        $(element).find('.subscribe-call-to-action').delay(15000).fadeIn(); // wait 15 seconds
      } else {
        $(element).find('.subscribe-call-to-action').fadeOut();
      }

      $(element).find('.subscribe-call-to-action-close').click(function(){
        setCookie(hide_subscriber_cookie_name, true, 14);
        $(element).find('.subscribe-call-to-action').fadeOut();
      });

      $(element).find('.subscribe-call-to-action-already-subscriber').click(function(){
        setCookie(is_subscriber_cookie_name, true, 365);
        $(element).find('.subscribe-call-to-action').fadeOut();
      });
    }
  });

  //Return API for other modules
  return {};
})(jQuery);

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
        c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
