import FeastGoogleAnalytics from 'feast/modules/google-analytics'

const _file = 'PlatformSearch';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var PlatformSearch = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find('.search-form').each(function() {
      var $search_form = $(this);
      if (!$search_form.is('form')) {
        // I don't know where the form .parent() is used, but it's how I found this code [SS]
        $search_form = $search_form.find('form').length > 0 ? $search_form.find('form') : $search_form.parent();
      }

      $search_form.on('search-complete', function(xhr, status) {
        FeastGoogleAnalytics.pageView($search_form.attr('action')+'?'+$search_form.serialize());
      });
    });
  });

  //Return API for other modules
  return {};
})(jQuery);
