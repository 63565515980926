"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OutlineButton = void 0;

var _elephantkit = require("@glasscanvas/elephantkit");

const OutlineButton = (0, _elephantkit.styled)("a", {
  backgroundColor: "transparent",
  border: "3px solid $white",
  borderRadius: "$round",
  fontSize: "$5",
  padding: "$2 $5",
  transition: "$normal",
  color: "$white",
  "&:hover": {
    color: "$label",
    backgroundColor: "$white"
  },
  variants: {
    contrastType: {
      dark: {
        color: "$label",
        border: "3px solid $label",
        "&:hover": {
          color: "$white",
          backgroundColor: "$label"
        }
      },
      light: {}
    }
  }
});
exports.OutlineButton = OutlineButton;