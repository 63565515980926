"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _HeroSection = require("../sections/HeroSection");

Object.keys(_HeroSection).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _HeroSection[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _HeroSection[key];
    }
  });
});

var _ImageCollage = require("./ImageCollage");

Object.keys(_ImageCollage).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ImageCollage[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ImageCollage[key];
    }
  });
});

var _ImageCollage2 = require("./ImageCollage2");

Object.keys(_ImageCollage2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ImageCollage2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ImageCollage2[key];
    }
  });
});

var _OutlineButton = require("./OutlineButton");

Object.keys(_OutlineButton).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _OutlineButton[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _OutlineButton[key];
    }
  });
});

var _ColoredBackground = require("./ColoredBackground");

Object.keys(_ColoredBackground).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ColoredBackground[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ColoredBackground[key];
    }
  });
});

var _image_uploader = require("./image_uploader");

Object.keys(_image_uploader).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _image_uploader[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _image_uploader[key];
    }
  });
});