/**
 * Created by StefanS on 2015-Sept-17
 *  - JoeKrump had originally put this in main.js
 */

const _file = 'GlassBrowser';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassBrowser = (function ($) {
  function initBrowserDetermination() {
    var browser   = '',
        userAgent = window.navigator.userAgent,
        platform  = window.navigator.platform.toLowerCase();

    // TODO: Why is this here (JA)
    $('.btn-anchor').removeAttr('disabled');

    // check for mobile firefox.
    if (platform.indexOf("firefox") > -1) {
      browser = 'mozilla';
    } else if (userAgent.indexOf('MSIE 10') > -1) {
      browser = 'ie-10';
    } else if (!!userAgent.match(/Trident\/7\./)) {
      browser = 'ie-11';
    } else if (userAgent.indexOf('Chrome') > -1) {
      browser = 'chrome';
    } else if (userAgent.indexOf('CriOS') > -1) {
      browser = 'chrome-mobile';
    }

    // Add browser name to body element
    $('body').addClass(browser);

    return browser;
  }

  initBrowserDetermination();

  // Return API for other modules
  return {
  };
})(jQuery);
