const _file = 'TilmaNavs';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var TilmaNavs = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $hover_btns = $(element).find('.hover-dropdown');
    // TODO: on hover of menu, trigger these same functions for the corresponding btn
    $hover_btns.hover(
      function() {
        var $btn = $(this);
        $btn.data('dropdown-will-close', false);
        if (!$btn.data('dropdown-open')) {
          $btn.data('dropdown-open', true);
          $btn.dropdown('toggle');
        }
      }, function() {
        var $btn = $(this);
        $btn.data('dropdown-will-close', true);
        setTimeout(function() {
          if ($btn.data('dropdown-open')) {
            $btn.data('dropdown-open', false);
            $btn.dropdown('toggle');
          }
        }, 1500);
      }
    );

    // Nav builder
    var $nav_wrapper = $(element).find('#nav_item_measure_wrapper .nav-link').parent();
    var nav_item_classes = $nav_wrapper.find('.nav-link').attr('class');
    var $nav_item = '<a href="javascript:void(0);" id="nav_item_measure" class="' + nav_item_classes + '"></a>';

    $nav_wrapper.html($nav_item);
    $nav_item = $(element).find('#nav_item_measure');

    var update_nav_item = function() {
      if ($('#item_subnav_id').val()) {
        $nav_item.html($('#item_title').val() + ' <i class="icon icon-keyboard-arrow-down" aria-hidden="true"></i>');
      } else {
        $nav_item.html($('#item_title').val());
      }
    }

    $(element).find('#item_title, #item_subnav_id, #show_subnav').on('change keyup', function(){
      update_nav_item();
      //console.log($nav_item.outerWidth());
      $("#item_width").val($nav_item.outerWidth());
    });
    update_nav_item();

    $(element).find('#show_subnav').change(function(){
      if ($(this).prop('checked')) {
        $('#subnav_wrapper').show();
        $('#url_wrapper').hide();
        return true;
      }

      $('#subnav_wrapper').hide();
      $('#item_subnav_id').val(null);
      $('#url_wrapper').show();
    });
  });
})(jQuery);
