const _file = 'CanvasSubscribe';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var CanvasSubscribe = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $forms = $(element).find('#subscribe-form, .subscription-form')

    $forms.each(function () {
      var $form = $(this);
      var $container = $form.find('#open-form, .open-form');
      $form.click(function (e) {
        slideOpen($form);
      });

      $container.keydown(function(e) {
        slideOpen($form);
      });
    });
  });

  $(document).on('click', function(e) {
    if ($(e.target).closest('#subscribe-form, .subscription-form').length == 0) {
      $('#subscribe-form, .subscription-form').each(function () {
        var $container = $(this).find('#open-form, .open-form');
        if (!$container.hasClass('closed')) {
          $container.addClass('closed');
          $(this).parents('.subscription-wrapper').removeClass('open');
        }
      });
    }
  });

  function slideOpen($form) {
    var $container = $form.find('#open-form, .open-form');
    if ($container.hasClass('closed')) {
      $container.removeClass('closed');
      $form.parents('.subscription-wrapper').addClass('open');
      $form.find('#first-name, .re-focus').focus();
    }
  }

  //Return API for other modules
  return {
  };
})(jQuery);
