import GlassScroll from 'glass/modules/scroll'

const _file = 'tilmaMemberProfile';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var tilmaMemberProfile = (function($){

  function loadTabFromHash(element) {
    var hash = document.location.hash;
    if (hash) {
      var $new_tab = $(element).find(hash + '-tab');

      if ($new_tab.length > 0) {
        $new_tab.tab('show');
      }
    }
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $wrapper = $(element).find('#member-tabs');

    if ($wrapper.length > 0) {
      $wrapper.find('[data-toggle="tab"]').click(function(){
        window.location.hash = $(this).attr('href');
      });

      loadTabFromHash(element);
      GlassScroll.scrollTo($wrapper, 64);

      window.addEventListener('hashchange', e => {
        loadTabFromHash(element);
      });
    }
  });
})(jQuery);
