import GlassScroll from 'glass/modules/scroll';

// these require statements cause duplicate jquery or something
// window.$.mask = require('jquery-mask-plugin');
// window.$.validate = require('jquery-validation');

/* Validation */

var count = '';
var email_invalid_message = 'Please enter a valid Email address.';

if (window.I18n && window.I18n['email_invalid']) {
  email_invalid_message = window.I18n['email_invalid'];
}

var validator;

function validateAndScroll($form) {
  var valid = $form.valid();
  $form.data("preprocess-pending", !valid);

  if (!valid) {
    GlassScroll.scrollTo($form.find('.has-error').first(), 250, function () {
      validator.focusInvalid();
    });
  }
}

const _file = 'ValidateForm';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var ValidateForm = function ($) {
  $.validator.addMethod("validate_email", function (value, element) {
    if (/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
      return true;
    }
    return false;
  }, email_invalid_message);

  $.validator.addClassRules("email", {
    validate_email: true,
    required: true
  });

  $.validator.addClassRules("url", {
    url: true
  });

  $.validator.addClassRules("required", {
    required: true
  });

  $.validator.addMethod("check_min_value", function (value, element) {
    count = $(element).data('min-value');
    if (window.I18n && window.I18n['check_min_value']) {
      $.validator.messages["check_min_value"] = window.I18n['check_min_value'].replace("${count}", count);
    } else {
      $.validator.messages["check_min_value"] = "Please enter a value greater or equal to " + count;
    }
    return value >= count;
  }, "");

  $.validator.addClassRules("min_value", {
    required: true,
    check_min_value: true,
    number: true
  });

  $.validator.addMethod("check_max_value", function (value, element) {
    count = $(element).data('max-value');
    if (window.I18n && window.I18n['check_max_value']) {
      $.validator.messages["check_max_value"] = window.I18n['check_max_value'].replace("${count}", count);
    } else {
      $.validator.messages["check_max_value"] = "Please enter a value lower or equal to " + count;
    }
    return value <= count;
  }, "");

  $.validator.addClassRules("max_value", {
    required: true,
    check_max_value: true,
    number: true
  });

  function initFormValidation(element) {
    $(element).find('form.validate-form').each(function () {
      $(this).data("preprocess-pending", true); // the api into CanvasForms

      validator = $(this).validate({
        //ignore: '*:not([name])',
        ignore: ":hidden:not(.required)",
        rules: {
          /*
          province: {
            required: function(element) {
              return $("#country option:selected").val() === "Canada";
            }
          },
          province2: {
            required: function(element) {
              return $("#country option:selected").val() === "United States";
            }
          },
          province_text: {
            required: function(element) {
              return (($("#country option:selected").val() !== "United States") &&
                     ($("#country option:selected").val() !== "Canada"));
            }
          },*/
        }, /*
           messages: {
           exp_month: 'Missing',
           exp_year: 'Missing'
           },*/
        onfocusout: function (element) {
          this.element(element);
        },
        highlight: function (element) {
          $(element).addClass('has-error');
          $(element).closest('.form-group').addClass('has-error');
        },
        unhighlight: function (element) {
          $(element).removeClass('has-error');
          $(element).closest('.form-group').removeClass('has-error');
        }, /*
           errorContainer: '#form-validation-errors',
           errorLabelContainer: 'ul.form-errors',
           errorElement: 'li',
           errorClass: 'red',
           showErrors: function(errorMap, errorList) {
           if (submitted) {
             var errors = $('form.validate-form').validate().errors();
             //console.log(errors);
             var summary = "";
             $('#form-validation-errors').fadeIn();
             $.each(errorList, function () { console.log(this); summary += "<li class='red'>" + this.message + "</li>"; });
             $('#error-message-box').html(summary);
             this.defaultShowErrors();
             submitted = false;
           }
           },*/
        errorElement: 'div',
        errorClass: 'error-text',
        errorPlacement: function (error, element) {
          if (element.parent('.form-group').length) {
            if (element.parent().find('label.placeholder-label').length) {
              // If the element is in a form-group div and has a placeholder label
              // then append into the placeholder label div
              error.appendTo(element.parent().find('label.placeholder-label'));
            } else {
              // in this case the element is within a form group but doesn't have
              // a placeholder-label, so append the error to the end of the parent
              // element (which is the form-group)
              element.parent().append(error);
            }
          } else if (element.parent('.custom-radio').length) {
            error.insertAfter(element.parent().parent());
          } else if (element.parent('.custom-checkbox').length) {
            error.insertAfter(element.parent());
          } else {
            error.insertAfter(element);
          }
        },
        onsubmit: false
      });

      $(this).on('form-before-submit', function (e) {
        validateAndScroll($(this));
      });
    });
  }

  function initMasking(element) {
    $(element).find('.tel').mask('000-000-0000', { placeholder: "(___) ___-____" });
    $(element).find('.url').mask('http://ZZ', { translation: { 'Z': { pattern: /\S/, optional: true, recursive: true } }, placeholder: "http://" });
    $(element).find('.number').mask('ZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: true, recursive: true } } });
    $(element).find('.number-decimal').mask('ZZ', { translation: { 'Z': { pattern: /[0-9.]/, optional: true, recursive: true } } });
    $(element).find('.money').mask('000,000,000.00', { reverse: true });
  }

  // zip / postal code based on country
  function setPostal($field, $country) {
    var label_text = "";
    var input_placeholder = "";
    if ($country.val() == "United States") {
      if (window.I18n && window.I18n['zip_code']) {
        label_text = input_placeholder = window.I18n['zip_code'] + ' *';
      } else {
        label_text = input_placeholder = 'Zip code *';
      }
    } else {
      if (window.I18n && window.I18n['postal_code']) {
        label_text = input_placeholder = window.I18n['postal_code'] + ' *';
      } else {
        label_text = input_placeholder = 'Postal code *';
      }
    }
    $field.find('label').text(label_text);
    $field.find('input').attr('placeholder', input_placeholder);
  }

  function initAddress(element) {
    var $postalCodeFields = $(element).find('.postal-prereq');
    $postalCodeFields.each(function () {
      var $field = $(this);
      var $country = $($field.data('prereq-sel'));
      setPostal($field, $country);
      $country.change(function (e) {
        setPostal($field, $country);
      });
    });
  }

  function initPayment(element) {
    $(element).find('.exp_month').mask('00', { placeholder: 'MM' });
    $(element).find('.exp_year').mask('00ZZ', { placeholder: 'YY', translation: { 'Z': { pattern: /[0-9]/, optional: true } } });
    $(element).find('.cvc').mask('000Z', { translation: { 'Z': { pattern: /[0-9]/, optional: true } } });
  }

  function initAutotab(element) {
    /* Implement an 'autotab' feature for any fields with the autotab
     * class. Automatically moves focus to the id of the field specified
     * in the 'next' data field. */
    $(element).find(".autotab").keyup(function () {
      if ($(this).val().length == $(this).attr('maxlength')) {
        $($(this).data('next')).focus();
      }
    });
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    initFormValidation(element);
    initMasking(element);
    initAddress(element);
    initPayment(element);
    initAutotab(element);

    document.querySelectorAll("[maxlength]").forEach(input => {
      updateCharCounter(input, input.value.length);
      input.addEventListener('change', e => {
        updateCharCounter(input, e.currentTarget.value.length);
      });
      input.addEventListener('keyup', e => {
        updateCharCounter(input, e.currentTarget.value.length);
      });
    });

    function updateCharCounter(input, charCount) {
      var charCounter = input.parentNode.querySelector('.char-count');
      if (charCounter) {
        charCounter.innerHTML = `${charCount}`;
      }
    }
  });
}(jQuery);

export { validateAndScroll, ValidateForm };