function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./modules/', true, /\.js$/));

import "cocoon-js";

// got external dependencies from npm
// import 'canvas/jquery.stellar'
// import 'canvas/stickyfill'

// import 'canvas/waypoints'
// import 'canvas/modules/stickyfill'
// import 'canvas/modules/parallax'
// import 'canvas/modules/carousel'
import 'canvas/modules/navigation'
import 'canvas/modules/btn-group'
import 'canvas/modules/subscribe'
import 'canvas/modules/form-prereqs'
// import 'glass/modules/menus'
// import 'glass/modules/dimmer'
import 'glass/modules/search'

// ### Extension manifest files listed here
// ### import 'glass/manifest-back-and-frontend' imported directly from pack file, keep this line here commented out