import CanvasForms from 'glass/modules/forms'

const _file = 'tilmaMembershipForms';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var tilmaMembershipForms = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $fields_to_hide = $(element).find('.financial-fields');
    var $checkbox = $("input.is-redundant-profile");

    if ($fields_to_hide.length > 0 && $checkbox.length > 0) {
      function showHideFinancials($checkbox) {
        if ($checkbox.is(':checked')) {
          $fields_to_hide.addClass('d-none');
        }
        else {
          $fields_to_hide.removeClass('d-none');
        }
      }

      var $checkbox = $("#member_financial_profile_is_redundant_profile");
      $checkbox.change(function() {
        showHideFinancials($(this));
      });

      // init state
      showHideFinancials($checkbox);
    }
  });
})(jQuery);
