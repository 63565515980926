const _file = 'BlogProgressBar';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var BlogProgressBar = (function ($) {
  function reinitWithin($elem) {
    var $progress_bars = $('.read-progress');

    $progress_bars.each(function() {
      var $progress_bar = $(this);
      var $content = $($progress_bar.data('content-selector'));

      if ($content.length > 0) {
        $progress_bar.removeClass('d-none');

        $(window).on('scroll', function(e) {
          var $item, closest_to_view = false;
          $content.each(function(i, el){
            var percent = ($(window).scrollTop() - $(el).offset().top) / ($(el).height() - $(window).height()) * 100;
            var distance_from_center = (percent > 50) ? percent - 50 : 50 - percent;
            if (
              closest_to_view == false ||
              distance_from_center < closest_to_view
            ) {
              closest_to_view = distance_from_center;
              $item = $(el);
            }
          });
          var percent = ($(window).scrollTop() - $item.offset().top) / ($item.height() - $(window).height()) * 100;
          percent = percent > 100 ? 100 : percent;
          $progress_bar.val(percent);
          $(document).trigger('scroll-progress', percent);
        });
      }
    });
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    reinitWithin($(element));
  });

  //Return API for other modules
  return {
    reinitWithin: reinitWithin
  };
})(jQuery);

export default BlogProgressBar
