const _file = 'tilmaAds';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var tilmaAds = (function($){

   function isScrolledIntoView(elem) {
     var docViewTop = $(window).scrollTop();
     var docViewBottom = docViewTop + $(window).height();

     var elemTop = $(elem).offset().top;
     var elemBottom = elemTop + $(elem).height();

     return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
   }

   function checkForImpression() {
     $(".advertisement").each(function() {
        //console.log($(this));
        if (isScrolledIntoView($(this))) {
          var register_impression = $(this).data('register-impression');

          if (typeof register_impression === 'undefined') {

            $.ajax({
              context: $(this),
              url: '/ads/register_impression',
              headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
              },
              beforeSend: function(xhr) {
                $(this).data('register-impression', true);
              },
              type: 'POST',
              data: {"ad_placement_id" : $(this).data('ad-placement-id')}
            });
          }
        }
      });
   }

   function enableClickTracking() {
     $('.advertisement a').click(function() {

       //var placement_id = $(this).parent().data('ad-placement-id');
       var register_click = $(this).data('register-click');

       if (typeof register_click === 'undefined') {

         $.ajax({
           context: $(this),
           url: '/ads/register_click',
           headers: {
             'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
           },
           beforeSend: function(xhr) {
             $(this).data('register-click', true);
           },
           type: 'POST',
           data: {"ad_placement_id" : $(this).parent().data('ad-placement-id')}
         });
       }
     });
   }

   $(document).on('content-ready', _double_include ? null : function (e, element) {
     if ($('body.cms-interface').length == 0 ) {

       window.onscroll=window.onresize= function() {
         checkForImpression()
       }

       checkForImpression();
       enableClickTracking();
     }

      $(element).find(".hide-if-no-ad").each(function() {
        var elementToHide = $(this);
        if (elementToHide.find(".no-ad").length > 0) {
          elementToHide.addClass("d-none");
        }
      });
  });
})(jQuery);
