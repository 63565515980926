/**
 * Created by StefanS on 2018-Jul-12
 */

const _file = 'GlassCollapse';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassCollapse = (function ($) {
  function collapseBasedOn($checkbox) {
    $($checkbox.data('controls-collapse')).collapse($checkbox.prop('checked') ? 'show' : 'hide');
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find('[data-controls-collapse]').each(function (){
      collapseBasedOn($(this));

      $(this).change(function(e) {
        collapseBasedOn($(this));
      });
    });
  });

  // Return API for other modules
  return {
  };
})(jQuery);
