import GlassUploader from 'glass/modules/glass-uploader'

const _file = 'GlassResourceUploader';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassResourceUploader = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {

    var $upload_btns = $(element).find('.resource-upload-btn');

    if ($upload_btns.length > 0) {

      $upload_btns.each(function () {

        var $upload_btn   = $(this);
        var $resource_field    = $('#' + $upload_btn.data('resource-input-id'));
        var $container    = $(this).parents('.resource-container');
        var $progress_bar = ($container.length > 0 ? $container : $upload_btn               ).find('.progress');
        var $error_div    = ($container.length > 0 ? $container : $resource_field.parents('form')).find(".errorExplanation").first();

        $upload_btn.click(function (e) {
          e.preventDefault();
          GlassUploader.openFileInput('#resource-upload-form', $upload_btn);
          $progress_bar.val(0);
        });

        GlassUploader.handleProgressUpdates($upload_btn, $progress_bar);

        $upload_btn.data('on-success', function(response) {
          $progress_bar.addClass('d-none');
          $upload_btn.text("Replace File");
          $container.find('div#' + $upload_btn.data('resource-input-id') + '_url').html("<a href='" + response.url + "'>Download File</a>");
          $container.find('#' + $upload_btn.data('resource-input-id')).val(response.resource_id);

          var $error_div = $container.find(".errorExplanation");
          if ($error_div.length > 0) {
            $error_div.removeClass('active');
            $error_div.html('');
          }
        });

        $upload_btn.data('on-error', function(response_text) {
          console.log(response_text);
          var $error_div = $container.find(".errorExplanation");
          if ($error_div.length > 0) {
            $error_div.html("<p>" + response_text + "</p>")
            $error_div.addClass('active');
          }
        });
      })
    }

  });

  // Return API for other modules
  return {
  };
})(jQuery);
