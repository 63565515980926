const _file = 'tilmaFrontendTopics';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var tilmaFrontendTopics = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {

    $('.follow-topic').click(function() {
      $.ajax({
        context: $(this),
        url: '/member/follow_category',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSend: function(xhr) {
          $(this).data('orig-btn-text', $(this).html());
          $(this).html('<i class="active inline xs loader"></i>' + $(this).html());
          $(this).attr('disabled', 'disabled');
        },
        type: 'POST',
        data: {"category_id" : $(this).data('category-id')},
        success: function(data) {
          $(this).html($(this).data('orig-btn-text'));
          $(this).attr('disabled', false);
          $(this).closest('.btn-follow').addClass('active');
        },
        error: function(data) {
          $(this).html($(this).data('orig-btn-text'));
          $(this).attr('disabled', false);
        }
      });
    });

    $('.unfollow-topic').click(function() {
      $.ajax({
        context: $(this),
        url: '/member/unfollow_category',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSend: function(xhr) {
          $(this).data('orig-btn-text', $(this).html());
          $(this).html('<i class="active inline xs loader"></i>' + $(this).html());
          $(this).attr('disabled', 'disabled');
        },
        type: 'POST',
        data: {"category_id" : $(this).data('category-id')},
        success: function(data) {
          $(this).html($(this).data('orig-btn-text'));
          $(this).attr('disabled', false);
          $(this).closest('.btn-follow').removeClass('active');
        },
        error: function(data) {
          $(this).html($(this).data('orig-btn-text'));
          $(this).attr('disabled', false);
        }
      });
    });
  });
})(jQuery);
