const _file = 'SubscriptionSettings';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var SubscriptionSettings = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find(".notification-toggle").on('change', function(){
      var $btn = $(this);

      $('.auto-save-state').addClass('active');

      var post_data = {};
      var method_type = 'POST';
      var url = '';

      if ($btn.prop('checked')) {
        method_type = $btn.data('method-check');
        url = $btn.data('url-check');
      } else {
        method_type = $btn.data('method-uncheck');
        url = $btn.data('url-uncheck');
      }

      if (method_type == 'POST' && $btn.data('post')) {
        post_data = $btn.data('post');
      }

      $.ajax({
        url: url,
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
        type: method_type,
        data: post_data,
        success: function(result) {
          if (result && result.delete_url) {
            $btn.data('url-uncheck', result.delete_url);
          } else {
            $btn.data('url-uncheck', 'no-subscription-to-delete');
          }
        },
        complete: function() {
          setTimeout(function(){
            $('.auto-save-state').removeClass('active');
          }, 800)
        }
      });
    });

    $(element).find('.notification-unsubscribe-all').on('click', function() {
      var $btn = $(this);

      $('.auto-save-state').addClass('active');

      var post_data = {};
      if ($btn.data('post')) {
        post_data = $btn.data('post');
      }

      $.ajax({
        url: $btn.data('url'),
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
        type: 'POST',
        data: post_data,
        success: function() {
          var $notification_toggles = $(element).find('.notification-toggle');
          if ($btn.data('subset-id')) {
            $notification_toggles = $(element).find('.notification-toggle[data-subset-id=' + $btn.data('subset-id') + ']');
          }
          $notification_toggles.prop('checked', false);
          $notification_toggles.data('url-uncheck', 'no-subscription-to-delete');
        },
        complete: function() {
          setTimeout(function(){
            $('.auto-save-state').removeClass('active');
          }, 800)
        }
      });
    });

    $(element).find('.unsubscribe-shortcut').on('click', function() {
      var $btn = $(this);
      $(`input.notification-toggle[data-slug="${$btn.data('list-slug')}"]`).first().trigger('click')
      $('.subscriber-settings-pg-body').addClass('d-none')
      $('.unsubscribe-confirmation-pg-body').removeClass('d-none')
    });
  });

  //Return API for other modules
  return {
  };
})(jQuery);

export default SubscriptionSettings