const _file = 'Giving';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var Giving = (function ($) {
  function week_cents_to_dollars(weekly_cents) {
    var amount = (weekly_cents / 100).toFixed(2);
    return amount.indexOf('.00') > 0 ? Math.round(amount) : amount;
  }

  function week_cents_to_month_dollars(weekly_cents) {
    var amount = (week_cents_to_dollars(weekly_cents) * 4).toFixed(2);
    return amount.indexOf('.00') > 0 ? Math.round(amount) : amount;
  }

  // function month_dollars_to_week_cents(monthly_dollars) {
  //   return (monthly_dollars / 4.0 / 100.0).toFixed(2);
  // }

  /**
   * 
   * @param {JQuery.ChangeEvent<HTMLElement, null, HTMLElement, HTMLElement>} e
   * @param {HTMLElement} $elem 
   * @param {boolean} from_btn_click 
   * @returns {void}
   */
  function handle_input_change(e, $elem, from_btn_click) {
    var weekly_cents = Math.round($elem.val() * 100.0);
    var weekly_dollars = week_cents_to_dollars(weekly_cents);
    var monthly_dollars = week_cents_to_month_dollars(weekly_cents);
    $($elem.data('to-cents')).each(function() {
      $(this).val(weekly_cents);
    });
    // .not($elem)
    var $box = $elem.parents('.card-body, .container').first();
    $box.find('.weekly-donation-amt').html(weekly_dollars)
    $box.find('.monthly-donation-amt').html(monthly_dollars)
    if (from_btn_click) {
      // $elem.focus();
    }
    else {
      // btn-group.js changes all inputs on button click, but not on input change (sync all inputs)
      var $other_dollar_inputs = $('input[data-to-cents="' + $elem.data('to-cents') + '"]');
      $other_dollar_inputs.each(function() {
        $(this).val($elem.val());
      });

      // on change of input, make "Other" active (data-val="")
      var $btns = $('.btn[data-btn-group="' + $other_dollar_inputs.data('btn-group') + '"]');
      $btns.removeClass('active');
      $btns.filter('[data-val=""]').addClass('active');
    }

    // set external donation url params
    if ($box.find('.donation-amount-param').length > 0) {
      $box.find('.donation-amount-param').val($elem.val());
    }
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find(".giving-campaign").change(function(){
      var campaign_id  = $(this).val(),
          campaign_id  = !campaign_id ? 0 : campaign_id,
          $campaign_bg = $(element).find(".giving-campaign-background"),
          $campaign    = $(element).find(".giving-campaign-section[data-campaign-id='" + campaign_id + "']"),
          bg_styles    = 'display:none; z-index: 1; background-image:url(' + $campaign.data("campaign-image-url") + ');';

      // Transition campaign content
      $(element).find(".giving-campaign-section").not($campaign).fadeOut(function(){
        $campaign.fadeIn();
      });

      // Transition campaign background
      // We only need the bg-shadow-overlay for campaigns (not the general view)
      $campaign_bg.parent().append(
        $campaign_bg.clone()
          .attr('style', bg_styles)
          .removeClass('bg-shadow-overlay')
          .addClass(campaign_id ? 'bg-shadow-overlay' : ''
      ).fadeIn(function(){
        $campaign_bg.remove();
      }));

      // Swap statement descriptor
      var new_descriptor = $campaign.data("campaign-statement-descriptor");
      $(element).find('.choose-campaign .campaign-statement-descriptor').html(new_descriptor);
    });

    function updateInputValueToCents(el) {
      el.val(($(el.data('to-cents')).val() / 100.00).toFixed(2));
    }

    $(element).find('input[data-to-cents]').each(function() {
      var $to_cents_input = $(this);
      updateInputValueToCents($to_cents_input)
      $to_cents_input.on('change', function (e) {
        updateInputValueToCents($to_cents_input)
        handle_input_change(e, $(this), false);
      });
      $to_cents_input.on('click', function(e) {
        if (e.currentTarget.value === '0.00') {
          e.currentTarget.select()
        }
      })
      $to_cents_input.on('changed', function (e) { // from btn-group
        handle_input_change(e, $(this), true);
      });
      $to_cents_input.on('keyup', function (e) {
        handle_input_change(e, $(this), false);
      });
      handle_input_change(null, $to_cents_input, false);
    });

    $(element).find('.toggle-donation-options').click(function (e) {
      e.preventDefault();
      var val = $(this).data('val');

      // hide donation amount options when switching between onetime & weekly
      if ($(element).find('.donation-hide.donation-option-' + val).length > 0) {
        $(element).find('.donation-hide').addClass('d-none');
        $(element).find('.donation-hide.donation-option-' + val).removeClass('d-none');
      }

      // set donation form action for external form
      if ($(element).find('#donation_url_' + val).length > 0) {
        var new_action = $(element).find('#donation_url_' + val).val();
        $(this).closest('form').prop('action', new_action);
      }

      // disable donation fields for external form
      if ($(element).find('.donation-disable.donation-option-' + val).length > 0) {
        $(element).find('.donation-disable').prop('disabled', true);
        $(element).find('.donation-disable.donation-option-' + val).removeAttr('disabled');
      }
    });
  });
})(jQuery);
