/**
 * Created by jkrump on 2015-Mar-23
 * Refactored by Stefan Spicer on 2015-Jun-19
 * Updated by Sherwin Kwan on 2021-Mar-25
 */

const _file = "GlassInfiniteScroll";
const _double_include = $(window).data("included-" + _file);
$(window).data("included-" + _file, true);

var GlassInfiniteScroll = (function ($) {
  var fetch_in_progress = false;
  var container_selector = ".infinite-scroll-container";
  var load_more_selector = ".infinite-scroll-load-more";

  $(document).on(
    "content-ready",
    _double_include
      ? null
      : function (e, element) {
          var $container = $(element).find(container_selector);
          if ($(element).is(container_selector)) {
            $container = $(element);
          }

          if ($container.length > 0) {
            $container.each(function () {
              $(this).data("next-page", 2);
            });

            $(window).on("scroll", loadMoreItems);
            if ($(".admin-inner-wrapper").length > 0) {
              $(".admin-inner-wrapper").on("scroll", loadMoreItems);
            }
            if ($(".fixed-search-container").length > 0) {
              $(".fixed-search-container").on("scroll", loadMoreItems);
            }
          }

          $(element)
            .find(load_more_selector)
            .click(function (e) {
              e.preventDefault();
              var selector = container_selector,
                $element = $(container_selector);
              if ($(this).data("container")) {
                // container can be the infinite scroll container, or a parent element
                $element = $(
                  $(this).data("container") +
                    selector +
                    ", " +
                    $(this).data("container") +
                    " " +
                    selector
                );
                // console.log("DEBUG: using selector: " + selector);
              } else if ($(this).parents(selector).length > 0) {
                $element = $(this).parents(selector);
              }
              $element.removeClass("inactive");
              loadMoreItems();
              $(this).remove();
            });
        }
  );

  var loadMoreItems = function () {
    var $containers = $(container_selector).not(".inactive");
    if ($containers.length == 0) {
      return;
    }
    var $firstContainer = $containers.first();
    var total_pages = $firstContainer.data("pages-total");
    var next_page = $firstContainer.data("next-page");
    // DEBUG: console.log(`Next page is page ${next_page} of ${total_pages}`);
    var $spinner = $(".pagination-spinner");
    var spinner_is_almost_on_the_screen =
      $(window).scrollTop() + $(window).height() + 1200 > $spinner.offset().top;
    if (
      next_page <= total_pages &&
      !fetch_in_progress &&
      spinner_is_almost_on_the_screen
    ) {
      fetch_in_progress = true;
      $spinner.children().show();

      var url = $firstContainer.data("url");
      if (!url) {
        url = window.location.href;
      }
      url += (url.indexOf("?") !== -1 ? "&" : "?") + "page=" + next_page;

      $.get(url, function (data) {
        $spinner.children().hide(); // don't hide the container so we can still get the offset()

        $containers.each(function () {
          var $container = $(this);
          var this_container_selector = $container.attr("id")
            ? "#" + $container.attr("id")
            : container_selector;
          var $new_items = $(data).find(this_container_selector);
          if ($new_items.length > 0) {
            var $new_container = $("<div></div>").append($new_items.contents());
            $container.append($new_container);
            $(document).trigger("content-ready", $new_container); // only on the new items
            $new_container.children().unwrap();
          }

          $container.data("next-page", next_page + 1);
        });

        fetch_in_progress = false;
        loadMoreItems(); // In case user is waiting without scrolling, fetch again
      });
    }
  };

  return {
    loadMoreItems: loadMoreItems,
  };
})(jQuery);

export default GlassInfiniteScroll;
