/**
 * Created by StefanS on 2019-Mar-25
 */

const _file = 'GlassUrlParams';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassUrlParams = (function ($) {
  function get_param(url, key) {
    var results = new RegExp('[\?&]' + key + '=([^&#]*)').exec(url);
    return (results !== null) ? results[1] || 0 : false;
  }

  function rm_param(url, key) {
    var results = new RegExp('([\?&])' + key + '=[^&#]*&?').exec(url);
    url = results ? url.replace(results[0], results[1]) : url;
    url = url.replace(/\?$/, '')
    return url;
  }

  function set_param(url, key, val) {
    url = rm_param(url, key);
    return url + (url.includes('?') ? '&' : '?') + key + '=' + val;
  }

  // Return API for other modules
  return {
    rm_param: rm_param,
    get_param: get_param,
    set_param: set_param,
  };
})(jQuery);

export default GlassUrlParams
