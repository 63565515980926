const _file = 'FormPrereqs';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var FormPrereqs = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $prereqFields = $(element).find('[data-prereq-selector]')
    $prereqFields.each(function() {
      var $field = $(this);
      if ($field.data('prereq-vals') == 'undefined' && $field.data('prereq-vals-except') == 'undefined') {
        console.log("ERROR: An element with a data-prereq-selector attr is missing its data-prereq-vals attr");
        return;
      }

      var neg = typeof $field.data('prereq-vals') == 'undefined';

      if (!$field.data('prereq-vals-bool')) {
        $field.data('prereq-vals-array', $field.data('prereq-vals' + (neg ? '-except' : '')).toString().split(";"));
      } else {
        $field.data('prereq-vals-bool-val', $field.data('prereq-vals' + (neg ? '-except' : '')));
      }

      $($field.data('prereq-selector')).change(function(e) {
        var incl;
        if (!$field.data('prereq-vals-bool')) {
          incl = $field.data('prereq-vals-array').includes($(this).val());
          incl = incl || ( $field.data('prereq-vals-array').includes("##any##") && $(this).val() != "" );
        } else {
          incl = $field.data('prereq-vals-bool-val') == $(this).prop('checked');
        }

        if (neg == incl) {
          $field.addClass('d-none');
        }
        else {
          $field.removeClass('d-none');
        }
      });
      $($field.data('prereq-selector')).each(function() {
        $(this).change();
      });
    });
  });

  // Return API for other modules
  return {};
})(jQuery);
