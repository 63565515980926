import CanvasForms     from 'glass/modules/forms'

/**
 * Methods to control Bootstrap modals
 * @author Jkrump
 * @created 12-06-2014
 * @updated 04-20-2015
 */
const _file = 'GlassModals';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassModals = (function ($) {

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find('.btn-modal-cancel').click(function(e){
      e.preventDefault();
      var $modal = $(this).closest('.modal');
      $modal.modal('hide');
    });
    $(element).find('.open-modal').each(function () {
      setOpenBtnListeners($(this), undefined);
    });
  });

  /**
   * Sets listeners for the open modal btn.
   * @param $openBtn - DOM Object
   * @param successCallback - Method to call on completion of something (typically a form being submitted)
   */
  function setOpenBtnListeners($openBtn, successCallback){
    $openBtn.click(function(e) {
      e.preventDefault(); // Stops this from submitting a form if it is in one
      openBtnClickHandler($openBtn, successCallback);
    });
  }

  /**
   * Handler for open modal btn being clicked.
   *
   * @param $openBtn         <DOM Object> - The button that was clicked
   * @param successCallback  <function>   - A method to call once a form in the modal has been successfully submitted.
   */
  function openBtnClickHandler($openBtn, successCallback){
    var $modal        = $($openBtn.data('modal-selector') || '#sub-form-modal');
    var $modalContent = $modal.find('.description');

    var updateOnClose = $openBtn.data('update-on-close');
    var modalTitle    = $openBtn.data('modal-title')      ? $openBtn.data('modal-title')      : $openBtn.text();
    var submitBtnTxt  = $openBtn.data('modal-submit-btn') ? $openBtn.data('modal-submit-btn') : 'Submit';
    var $modalBody    = $openBtn.data('modal-body')       ? $($openBtn.data('modal-body'))    : [];
    var contentParams = {modalTitle: modalTitle, submitBtnTxt: submitBtnTxt};
    var url = $openBtn.attr('href') !== undefined ? $openBtn.attr('href') : $openBtn.attr('data-url');

    if($modal.hasClass('confirm')){
      // Modal has a form and it is a two step submission process.
      var $finalConfirmButton = $modal.find('.btn-confirm');
      var $previousConfirmButton = $modal.find('.btn-previous-confirm');

      if($previousConfirmButton.length > 0){
        $modal.modal({closable: false});
        $previousConfirmButton.unbind('click').click(function(e){
          e.preventDefault();
          if(successCallback !== undefined){
            successCallback();
          }
        });
        $finalConfirmButton.unbind('click').click(function(){
          $modal.modal('hide');
        });
      } else if($finalConfirmButton.length > 0){
        $finalConfirmButton.unbind('click').click(function(e){
          e.preventDefault();
          if(successCallback !== undefined){
            successCallback();
          }
        });
      }

      $modal.modal('show');
    } else if($modalBody.length > 0) {
      contentParams['body'] = $modalBody.html();
      initModalData($modal, successCallback, updateOnClose, contentParams);
    } else if(url && url.indexOf('#') != 0) { // $modalContent.find('#form-wrapper').length > 0){
      var formSourceSelector = ' ' + ($openBtn.attr('data-form-selector') || 'form');
      var formSourceUrl = (formSourceSelector === '') || (formSourceSelector === undefined) ? url : url+formSourceSelector;
      $modalContent.load(formSourceUrl, function(){
        initModalData($modal, successCallback, updateOnClose, contentParams);
      });
    } else {
      // If the modal already has a form in it, then just re-show the modal.
      $modal.modal('show');
    }
  }

  function initModalData($modal, successCallback, updateOnClose, contentParams) {
    if (contentParams['body']) {
      $modal.find('.description').html(contentParams['body']);
    }

    var $form         = $modal.find('form');
    var $saveBtn      = $modal.find('.btn-submit-modal');
    var saveBtnHtml   = $saveBtn.html();

    $modal.find('.header'          ).html(contentParams['modalTitle']);
    $modal.find('.btn-submit-modal').html(contentParams['submitBtnTxt']);
    $modal.modal('show');

    // should this make use of a forms.js method?
    $saveBtn.unbind().click(function(e){
      $form.trigger('form-before-submit');
      $form.submit();
      $saveBtn.html('<i class="active inline xs loader"></i> Sending');
      $saveBtn.attr('disabled', 'disabled');
    });

    $(document).trigger('content-ready', $modal);

    $form.data('on-complete-callback', function(response) {
      $saveBtn.html(saveBtnHtml);
      $saveBtn.removeAttr('disabled');

      if (successCallback !== undefined){
        successCallback(response);
      }

      if (updateOnClose){
        CanvasForms.ajaxUpdateContent(updateOnClose);
      }

      // Remove form (Simple solution atm to remove image)
      $modal.find('#form-wrapper').remove();
      $modal.modal('hide');

      return false;
    });
  }

  return {
    setOpenBtnListeners: setOpenBtnListeners,
    openBtnClickHandler: openBtnClickHandler
  };
})(jQuery);

export default GlassModals