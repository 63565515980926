import CanvasForms     from 'glass/modules/forms'

const _file = 'DynamicContent';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var DynamicContent = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $replacements = $(element).find('.dynamic-news');
    if ($replacements.length > 0) {
      var dest_url;
      $replacements.each(function() {
        var uri = $(this).data('dynamic-update-url');
        if (uri) {
          dest_url = uri;
        }
      });
      if (window.location.pathname.indexOf('/admin') != 0 && dest_url) {
        CanvasForms.ajaxUpdateContent('.dynamic-news', {'dynamicReplace' : 'true'}, dest_url);
      }
    }

    var $replacements = $(element).find('.dynamic-update');
    if ($replacements.length > 0) {
      var dest_urls = {};
      var dest_urls_ids = {};
      $replacements.each(function() {
        dest_urls[$(this).data('dynamic-update-url')] = true;

        if (!production && !$(this).attr('id')) {
          alert("ERROR: dynamic-update divs need 'id' attribute, this one doesn't: class=\"" + $(this).attr('class') + "\"")
        } else {
          dest_urls_ids[$(this).data('dynamic-update-url')] = dest_urls_ids[$(this).data('dynamic-update-url')] || [];
          dest_urls_ids[$(this).data('dynamic-update-url')].push($(this).attr('id'));
        }
      });

      if (window.location.pathname.indexOf('/admin') != 0 || $replacements.data('dynamic-update-force-admin')) {
        for (var dest_url in dest_urls) {
          CanvasForms.ajaxUpdateContent('.dynamic-update', {'dynamicReplace' : 'true'}, dest_url, dest_urls_ids[dest_url]);
        }
      }
    }
  });

  //Return API for other modules
  return {};
})(jQuery);
