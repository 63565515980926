import GlassScroll from 'glass/modules/scroll'

const _file = 'tilmaMembership';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var tilmaMembership = (function($){
  var cur_step = -1;
  var prev_step = -1;
  var visited_step = 0;

  function onLastStep() {
    return cur_step == (onboarding_steps_meta['steps'].length - 1);
  }

  function showNewCurStep($wrapper) {
    $wrapper.find('.step-wrapper').addClass('d-none');
    // onboarding_steps_meta set from ruby hash
    var step              = onboarding_steps_meta['steps'][cur_step],
        step_name         = step['name'],
        step_number       = 0,
        is_giving_enabled = $wrapper.attr('data-giving-enabled'),
        is_giving_enabled = is_giving_enabled.length ? '(giving ' + is_giving_enabled + ')' : '';

    $wrapper.find('[data-step="' + step_name + '"]').removeClass('d-none');

    var cur_menu_step_name = step['menu_step'];
    var found = false;

    $.each(onboarding_steps_meta['menu_index'], function(i, menu_step_name) {
      var $menu_step = $wrapper.find('.progress-steps-holder .progress-step[data-step="' + menu_step_name + '"]');

      if (found) {
        $menu_step.removeClass('active current')
      }
      else {
        $menu_step.addClass('visited');
        $menu_step.addClass('active');
        $menu_step.removeClass('current');
      }

      if (menu_step_name == cur_menu_step_name) {
        $menu_step.addClass('current');
        found = true;
        step_number = i;
        if (i > visited_step) {
          visited_step = i;
          var percent = parseInt(i / (onboarding_steps_meta['menu_index'].length - 1) * 100);
          $wrapper.find('.progress-bar').width(percent + '%');
        }
      }
    });

    // Track google analytics event
    if (typeof ga != 'undefined') {
      ga(
        'send',
        'event',
        'Membership',      // Event Category
        'Onboarding Step ' + is_giving_enabled, // Event Action
        step_name,         // Event label
        step_number        // Event value (integer)
      );
    }

    GlassScroll.scrollTo($wrapper, 64);
  }

  function prevStep($wrapper) {
    cur_step = prev_step;
    if (prev_step > 0) {
      prev_step--;
    }
    showNewCurStep($wrapper);
  }

  function nextStep($wrapper, skip = false) {
    prev_step = cur_step;

    // find the next step
    if (skip) {
      var cur_menu_step = onboarding_steps_meta['steps'][cur_step]['menu_step'];
      while(onboarding_steps_meta['steps'][cur_step]['menu_step'] == cur_menu_step) {
        ++cur_step;
      }
    }
    else {
      ++cur_step;
    }

    addStepToHistory(cur_step);
    showNewCurStep($wrapper);
  }

  function userActivityAround($elem) {
    $elem.parents('.step-wrapper').find('.next-step.alias-continue').each(function() {
      $(this).text('Save and continue');
    });
  }

  function addStepToHistory(step_number) {
    var current_state = {step: step_number};

    var step_name = onboarding_steps_meta['steps'][step_number].name;
    var menu_step = onboarding_steps_meta['steps'][step_number].menu_step;

    var step_title = onboarding_steps_meta['menu_steps'][menu_step].title;
    if (window.location.hash) {
      history.pushState(current_state, step_title, '/member/profile#' + step_name);
    } else {
      history.replaceState(current_state, step_title, '/member/profile#' + step_name);
    }
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $wrapper = $(element).find('#membership-onboarding');

    if ($wrapper.length > 0) {

      window.addEventListener('hashchange', e => {

        prev_step = cur_step
        var hash = window.location.hash.substring(1);

        $.each(onboarding_steps_meta['steps'], function(i, step) {
          if (hash == step.menu_step) {
            cur_step = onboarding_steps_meta["menu_index"].indexOf(hash);
          }
        });

        showNewCurStep($wrapper);
      });

      $('.progress-step').click(function(){
        if ($(this).hasClass('visited')) {
          window.location.hash = $(this).data('step');
        }
      });

      nextStep($wrapper);

      $wrapper.find('.next-step').click(function(e) {
        e.preventDefault();
        if ($(this).hasClass('skip-registration') && $('#member_completed_registration').val() == 'true') {
          $('#member_completed_registration').val('false');
        }
        if (!$(this).hasClass('dont-save')) {
          $(this).parents('.step-wrapper').find('form').slice(0,1).each(function() {
            if (!$(this).hasClass('process-subscription')) {
              $(this).data('on-complete-callback', function(result) {
                if (result['errors']) {
                  prevStep($wrapper);
                  return true;
                }
                else {
                  if (onLastStep()) {
                    location.reload();
                  }
                  return false;
                }
              });
              $(this).submit();
            }
          });
        }
        nextStep($wrapper, $(this).is('.skip-step'));
      });

      $wrapper.find('input,select,textarea').change(function() {
        userActivityAround($(this));
      });
      $wrapper.find('.btn-follow button').click(function() {
        userActivityAround($(this));
      });

      $wrapper.find('.process-subscription').first().data('on-complete-callback', function(result) {
        if (result['status'] == 'success') {
          nextStep($wrapper);
          return false;
        }
        else {
          return "no-redirect";
        }
      });
    }
  });
})(jQuery);
