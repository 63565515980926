/**
 * Created by StefanS on 2018-Oct
 * Other improvements to do:
 *  - Allow config to adjust the settings (based on the specifics of the site)
 *  - Option to disable form until human test passes (i.e. option to do server side validation only)
 */

const _file = 'BotBlock';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var BotBlock = (function ($) {
  var time_checkpoint_ms = 5000;
  var count_checkpoint   = 2;
  var count_max          = 100;

  var lastxy = [0,0];
  var last_top = $(window).scrollTop();
  var unique_actions = {};
  var actions = []
  var page_load_time = new Date().getTime();
  var last_action_time = page_load_time;
  var callbacks = [];

  var human = false;
  var initialized_events = false;
  var $notBotFields = $();
  var $forms = $();

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find('.i-am-not-a-80t').each(function () {
      var $form = $(this).parents('form');
      $notBotFields = $notBotFields.add($(this));
      $forms = $forms.add($form);

      if (human) { // already determined they are human before this form loaded via ajax
        $(this).val(calcFieldVal());
      }
    });
    init_events();
  });

  function calcFieldVal() {
    var timeval = parseInt((last_action_time / 1000) - 1463170858);
    return timeval + "-" + parseInt((timeval % 17) + (timeval / 31)) + "-" + JSON.stringify([actions, unique_actions]);
  }

  function on_action(meta, rapid_event) {
    var result = false;

    if (actions.length >= count_max) {
      $(document).off('mousemove.botblock');
      $(document).off('mousedown.botblock');
      $(document).off('click.botblock');
      $('input,textarea').off('keydown.botblock');
      $(window).off('scroll.botblock');
      return result;
    }

    var now = new Date().getTime();
    meta['time_diff'] = now - last_action_time;
    meta['ms']        = now - page_load_time;

    if (!meta['key']) {
      meta['key']    = parseInt(meta['time_diff'] / 10);
      meta['key_is'] = '10ths of second elapsed'
    }

    var k = meta['name'] + '&' + meta['key'];

    if (unique_actions[k]) {
      ++unique_actions[k];
    }

    if (!rapid_event || meta['time_diff'] > 100) {
      if (!unique_actions[k]) {
        unique_actions[k] = 1;
      }

      // skip mouse & scroll events that flood this array
      actions.push(meta);
      last_action_time = now;

      result = true;

      $.each(callbacks, function(i, callback) {
        callback(meta, actions, unique_actions);
      });
    }

    if (!human) { // doesn't yet look like a human
      if (Object.keys(actions).length > 5) { // detected enough variation (randomness) from a human
        human = true;
        $notBotFields.each(function() {
          $(this).val(calcFieldVal());
        });
      }
    }

    $forms.each(function() {
      $(this).find('[type="submit"]').prop('disabled', !human); // set to true each time because verify sets to false
    });

    return result;
  }

  function mouse_event(e, event_name) {
    var key = 'mouse-' + event_name;
    var recorded = on_action({
      name: 'mouse-' + event_name,
      key: ((lastxy[0] - e.pageX) + (lastxy[1] - e.pageY)),
      key_is: 'xy diff',
    }, event_name != 'click');

    if (recorded) {
      lastxy[0] = e.pageX;
      lastxy[1] = e.pageY;
    }
  }

  function init_events() {
    if (($notBotFields.length > 0 || callbacks.length > 0) && !initialized_events) {
      initialized_events = true;

      $(document).on('mousemove.botblock', function(e) {
        mouse_event(e, 'move');
      });

      $(document).on('mousedown.botblock', function(e) {
        mouse_event(e, 'down');
      });

      $(document).on('click.botblock', function(e) {
        mouse_event(e, 'click');
      });

      $('input,textarea').on('keydown.botblock', function(e) {
        on_action({name: 'keydown'}, false);
      });

      $(window).on('scroll.botblock', function(e) {
        var top = $(window).scrollTop();
        var recorded = on_action({
          name: 'scroll',
          key: top - last_top,
          key_is: 'scroll diff',
        }, true);

        if (recorded) {
          last_top = top;
        }
      });

      setTimeout(function() {
        on_action({name: 'time-checkpoint'}, false);
      }, time_checkpoint_ms);
    }
  }

  function register_callback(cb) {
    callbacks.push(cb);
  }

  // Return API for other modules
  return {
    register_callback: register_callback,
  };
})(jQuery);

export default BotBlock