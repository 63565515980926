import GlassScroll from 'glass/modules/scroll'
import BlogProgressBar from 'blog/modules/article-progress-bar'
import FeastGoogleAnalytics from 'feast/modules/google-analytics'

const _file = 'TilmaAutoloadAndSidebar';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var TilmaAutoloadAndSidebar = (function ($) {
  var $toRead = $('#empty-set');
  var loadingState = 'paused'; // or 'ready', 'in_progress', 'done_all'
  var card_selector = '.card-vertical, .card-horizontal, .content-list-item';

  function loadNextArticle() {
    if (loadingState != 'ready' || $toRead.length <= 1) {
      return true;
    }

    var $nextArticleMenuItem = $('#empty-set');
    $toRead.each(function() {
      if (!$(this).data('loaded') && $('#' + $(this).data('article-id')).length == 0) {
        $nextArticleMenuItem = $(this);
        $nextArticleMenuItem.data('loaded', true);
        return false;
      }
    });

    if ($nextArticleMenuItem.length > 0) {
      loadingState = 'in_progress';

      // load $nextArticleMenuItem
      var destUrl = $nextArticleMenuItem.attr('href') || $nextArticleMenuItem.find('a').first().attr('href');

      var newWrapperId = "new-article-load-wrapper";
      $('.article-load-wrapper').first().append('<div id="' + newWrapperId + '" style="display: none;"></div>')
      var $newWrapper = $('#' + newWrapperId);

    $.ajax({
      url: destUrl,
      method: 'GET',
      beforeSend: function( xhr ) {
        xhr.setRequestHeader('Accept', 'text/html');
      },
      success: function(data) {
        var $replacement = $(data).find('.autoload-content');
        $newWrapper.replaceWith($replacement);
        $(document).trigger('content-ready', $replacement);
        FeastGoogleAnalytics.pageView(destUrl);
        // progress bars init when their target content is present
        BlogProgressBar.reinitWithin($('#sidebar-recommended'));
        loadingState = 'ready'
      }
    });

      return true;
    }

    loadingState = 'done_all';
    return false;
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $sidebar = $('#sidebar-recommended');

    /* General initialization and adding current article to top of sidebar
     **********************************************************************/
    var $spinners = $(element).find('.article-page .pagination-spinner .load-more');
    var $autoload_control = $(element).find('.autoload-control');
    if ($autoload_control.length > 0) {
      if ($autoload_control.first().data('autoload-config') != 'init_with_btn') {
        loadingState = 'ready';
        $spinners.css('display', 'block');
      }
    }

    var new_sidebar_content = $(element).parents('#sidebar-recommended').length > 0 || $(element).is('#sidebar-recommended') || $(element).find('#sidebar-recommended').length > 0;
    var $sidebarItems = $sidebar.find(card_selector);
    if (new_sidebar_content && $sidebarItems.length > 0) {
      $toRead   = $('#empty-set');
      var $dups = $('#empty-set');
      $sidebarItems.each(function() {
        if ($(this).parents('#sidebar-recommended').length > 0) {
          if ($toRead.filter('[data-article-id="' + $(this).data('article-id') + '"]').length > 0) {
            $dups = $dups.add($(this));
          }
          else {
            $toRead = $toRead.add($(this));
          }
        }
      });

      $dups.remove();
      BlogProgressBar.reinitWithin($('#sidebar-recommended'));
    }

    /* Scroll on click
     ******************/
    $sidebarItems.click(function(e) {
      var destSelector = '#' + $(this).data('article-id');
      if ($(destSelector).length > 0) {
        e.preventDefault();
        GlassScroll.scrollTo(destSelector, 50);
      }
    });

    /* Load next article on scroll
     ******************************/
    if ($sidebar.length > 0 && $spinners.length > 0) {
      var $spinner = $spinners.first();

      $(window).scroll(function(e) {
        var spinner_is_almost_on_the_screen = $(window).scrollTop() + $(window).height() + 5000 > $spinner.offset().top;
        if (spinner_is_almost_on_the_screen) {

          var result = loadNextArticle();

          if (!result) {
            $spinner.css('display', 'none');
          }
        }
      });
    }

    /* "Load more" button to kick autoload off
     ******************************************/
    $(element).find('.load-more-articles-btn').click(function(e) {
      $('.load-more-articles-btn').remove();
      $spinners.css('display', 'block');
      loadingState = 'ready';
      loadNextArticle();
    });

    /* Adjust sidebar and address bar to display current article
     ************************************************************/
    if ($sidebar.length > 0) {
      $(window).scroll(function (e) {
        $sidebar.find('.read-progress').each(function() {
          var $thisItem = $(this).parents(card_selector),
              href = $thisItem.attr('href') || $thisItem.find('a').attr('href');
          if ($(this).val() > 0 && $(this).val() < 100 && !$thisItem.hasClass('active')) {
            $sidebar.find(card_selector).removeClass('active');
            $thisItem.addClass('active');
            $sidebar.find('.sidebar-sticky-inner').animate({
              scrollTop: $thisItem.offset().top - $sidebar.find('.sidebar-items-wrapper').offset().top
            }, 200, function() {});
            window.history.replaceState({}, '', href);
          }
        });
      });
    }
  });

  //Return API for other modules
  return {};
})(jQuery);
