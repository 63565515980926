const _file = 'FeastGoogleAnalytics';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var FeastGoogleAnalytics = (function ($) {
  function pageView(url = null) {
    if (typeof ga == "undefined") {
      return;
    }

    if (url == null) {
      url = window.location.href;
    }

    ga('send', 'pageview', url, ga_dimension_meta);

    if (window.org_analytics_page_code) {
      if (send_org_custom_dimensions) {
        ga('orgTracker.send', 'pageview', url, ga_dimension_meta);
      }
      else {
        ga('orgTracker.send', 'pageview', url);
      }
    }
  }

  if (typeof org_analytics_page_code == "undefined") {
    window.org_analytics_page_code = false;
    window.send_org_custom_dimensions = false;
  }

  if (typeof ga_dimension_meta == "undefined") {
    window.ga_dimension_meta = {};
  }

  if (typeof ga != "undefined") {
    if (window.org_analytics_page_code) {
      ga('create', window.org_analytics_page_code, 'auto', {'name': 'orgTracker'});
    }

    // Initial pageview, not triggered by new ajax or an event
    pageView(null);
  }

  return {pageView: pageView}
})(jQuery);

export default FeastGoogleAnalytics
