/**
 * Created by StefanS on 2015-Aug-20
 */

const _file = 'GlassButtons';
const _double_include = $(window).data('included-' + _file);

$(window).data('included-' + _file, true);

var GlassButtons = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find('[data-toggle-active]').each(function (){
      $(this).click(function (e) {
        var $elem = $($(this).data('toggle-active'));
        $elem.hasClass('active') ? $elem.removeClass('active') : $elem.addClass('active');
      });
    });

    $(element).find('[data-toggle-class]').each(function (){
      $(this).click(function (e) {
        var $elem = $($(this).data('toggle-class')['selector']);
        var klass = $(this).data('toggle-class')['class'];
        $elem.hasClass(klass) ? $elem.removeClass(klass) : $elem.addClass(klass);
      });
    });


    $(element).find("[title!=''][title]").each(function () {
      if ($(this).is("a, label, .btn, button") || $(this).data('toggle') == 'tooltip') {
        // $(this).attr("data-tooltip", $(this).attr("title"));
        // $(this).removeAttr("title");
        $(this).tooltip({trigger: "hover"});
      }
    });

    $(element).find("[data-toggle='popover']").each(function () {
      $(this).popover();
    });

    btnAnchorInitialization(element);
    initAjaxBtns(element);
  });

  // Useful within an <a> or if js needs to perform an action before redirect (e.g. page unload)
  function btnAnchorInitialization(element){
    var $btnAnchors = $(element).find('.btn-anchor'),
        $selectInput = $(element).find('.select-anchors');

    // On click, treat as link
    $btnAnchors.click(function (e) {
      var $btn = $(this),
          dataUrl = $btn.attr('data-url');

      if (!dataUrl || dataUrl.length == 0) {
        return true;
      }

      e.preventDefault();

      // if the btn has the class btn-anchor-outbound its url should be opened in a new window.
      if ($btn.hasClass('btn-anchor-outbound') || $btn.hasClass('btn-anchor-external')) {
        window.open(dataUrl);
      } else {
        window.location.href = dataUrl;
      }

    // On hover, change parent link href to show link
    }).on('mouseover', function (e) {
      var $anchor = $(this).parents('a'),
          dataUrl = $(this).attr('data-url');

      if (!dataUrl || dataUrl.length == 0) {
        return true;
      }

      $anchor.attr('data-reset-href', $anchor.attr('href'));
      $anchor.attr('href', dataUrl);

    // On hover out, reset parent link
    }).on('mouseout', function (e) {
      var $anchor = $(this).parents('a');
          resetUrl = $anchor.attr('data-reset-href');

      if (!resetUrl || resetUrl.length == 0) {
        return true;
      }

      $anchor.attr('href', resetUrl);
      $anchor.attr('data-reset-href', null);
    });

    $selectInput.change(function(e){
      e.preventDefault();
      if($selectInput.hasClass('anchors-outbound')){
        window.open($selectInput.val());
      } else {
        window.location.href = $selectInput.val();
      }
    });
  }


  function decodeHtml(html) {
    return $('<div>').html(html).text();
  }

  function initAjaxBtns(element) {
    $(element).find('.btn-ajax').each(function() {
      $(this).data('orig-text', $(this).html());
    });

    $(element).find('.btn-ajax').click(function(e) {
      e.preventDefault();
      var $btn = $(this);
      var post_data = {};
      var method_type = 'POST';

      if ($btn.data('post')) {
        // .data('post'), when well formed JSON seems to be parsed automatically
        // post_data = JSON.parse(decodeHtml($btn.data('post')));
        post_data = $btn.data('post');
      }

      if ($btn.data('post-callback')) {
        post_data = $btn.data('post-callback')($btn, e);
      }

      if ($btn.data('method')) {
        method_type = $btn.data('method');
      }

      if ($btn.hasClass('default-spinner')) {
        if ($btn.find('i').length == 0) {
          $btn.prepend('<i></i> &nbsp;');
        }
        var $icon = $btn.find('i').first();
        $icon.attr('class', $icon.get(0).className.replaceAll(/fa[^\s]*/g, '') + " fa fa-spin fa-refresh");
      }

      $.ajax({
        url: $btn.data('url'),
        type: method_type,
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        data: post_data,
        success: function(result) {
          if (result['redirect_url']) {
            $btn.data('success-url', result['redirect_url']);
          }
          if ($btn.data('success-callback')) {
            $btn.data('success-callback')($btn, result);
          }
          if ($btn.data('success-url')) {
            window.location.href = $btn.data('success-url');
          }
        },
        error: function(xhr, status, error_str) {
          var response = xhr.responseJSON;
          if (response && (response['errors'] || response['message'])) {
            $errorBox = $('#errorExplanation').first();
            if ($errorBox) {
              var errorMsg = "";
              if(response['errors']) {
                errorMsg += response['errors'];
              }
              else {
                errorMsg += response['message'];
              }
              $errorBox.find('ul').first().append('<li>' + errorMsg + '</li>')
              $errorBox.addClass('active');
              $errorBox.removeClass('d-none');
            }
            else {
              $btn.before('<p>' + response['message'] + '</p>')
            }
          }
        },
        complete: function(xhr, status) {
          if ($btn.data('on-complete-callback')) {
            $btn.data('on-complete-callback')($btn, {xhr: xhr, status: status});
          }
          if ($btn.hasClass('default-spinner')) {
            if ($btn.data('success-message')) {
              $btn.html($btn.data('success-message'));
              setTimeout(function() {
                $btn.html($btn.data('orig-text'));
              }, 5000);
            }
            else {
              $btn.html($btn.data('orig-text'));
            }
          }
        }
      });
    });
  }

  // Return API for other modules
  return {
  };
})(jQuery);
