import 'canvas/waypoints'

const _file = 'CanvasMenus';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var CanvasMenus = (function($){
  var $mainmenu;

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    initMainNav();
    initSubNavWithin(element);
    initCollapses(element);
  });

  function initMainNav() {
    $mainmenu = $('.navbar.fixed-top, .navbar.sticky, .navbar.position-sticky, .navbar.position-fixed');

    if (!$mainmenu.length) return false;

		// Hide navigation
		$('.cover').waypoint(function(direction) {
    	if (direction === 'down') {
        $mainmenu.addClass( "navbar-hide" );
	    }
		}, {
			offset: function() {
		    return -$('.cover').height() * 0.15;
		   }
		});

		$('.cover').waypoint(function(direction) {
    	if (direction === 'up') {
        $mainmenu.removeClass( "navbar-hide" );
	    }
		}, {
			offset: function() {
		    return -$('.cover').height() * 0.15;
		   }
		});

    // Auto show menu on scroll up
    $(window).on('scroll', function(e) {
      positionMainMenu();
      displayHideFixedMenus();
    });
  }

  // ajax load sub pages when the subnav is below the cover
  // A sliding object (.notched-borders arrow) that moves to the active sub nav item
  function initSubNavWithin(element) {
    var $subnav = $(element).find('#about-nav');
    var btn = 'button';
    if ($subnav.length > 0 && $subnav.parents('#page-preview').length == 0) {
      $subnav.find(btn).click(function(e) {
        e.preventDefault();
        $(this).parent().find(btn).removeClass('active');
        $(this).addClass('active');
        var dest_url = $(this).attr('href');

        $('.notched-borders').removeClass('one').removeClass('two').removeClass('three').removeClass('four').addClass($(this).data('pos'));

        var $content_container = $('.page-content').parent();
        $content_container.load(dest_url + ' .page-content', function () {
          $(document).trigger('content-ready', $content_container[0]);
        });

        if(history.pushState){
          history.replaceState({isModal: true}, null, dest_url);
          //history.pushState({}, null, dest_url);
        }
      });
    }
  }

  var last = {
    scrollTop: 0,
    lowPoint: 0,
    highPoint: 99999,
  }

  var conf = {
    scrollPxToShow: 150,
    scrollPxToHide: 75,
  }

  function modifyFixedMenu(mType, showHide) {
    var $menus = $('[show-on-scroll="' + mType + '"]');
    showHide == 'show' ? $menus.addClass('show') : $menus.removeClass('show');
  }

  // To use, just add a show-on-scroll="up" (or "down") to the nav
  function displayHideFixedMenus() {
    var scrollTop = $(window).scrollTop();
    var coverHeight = $('.cover').length > 0 ? $('.cover').outerHeight() : 0;

    // no cover, set min height so the nav stays for a bit at the top
    coverHeight = coverHeight < 200 ? 200 : coverHeight;

    if (scrollTop > last['scrollTop']) { // going down
      last['lowPoint'] = scrollTop;

      if (scrollTop > last['highPoint'] + conf['scrollPxToShow']) {
        modifyFixedMenu('down', 'show');
      }
      if (scrollTop > last['highPoint'] + conf['scrollPxToHide']) {
        modifyFixedMenu('up', 'hide');
      }
    }
    else { // going up
      last['highPoint'] = scrollTop;

      if (scrollTop < last['lowPoint'] - conf['scrollPxToShow']) {
        modifyFixedMenu('up', 'show');
      }
      if (scrollTop < last['lowPoint'] - conf['scrollPxToHide']) {
        modifyFixedMenu('down', 'hide');
      }
    }

    last['scrollTop'] = scrollTop;
  }

  var lastScrolltop = 0;
  var lastLowPoint  = 0;
  var coverRemoved = false;

  // controls the display behavior of the top nav menu on scroll.
  function positionMainMenu() {
    var scrolltop = $(window).scrollTop();
    var coverHeight = $('.cover').outerHeight();
    var navbarHeight = $mainmenu.outerHeight();
    var navbarTop = $mainmenu.offset().top;
    var scrollUpOffset = 100;
    var scrollDownOffset = 30;

    // no cover, set min height so the nav stays for a bit at the top
    coverHeight = coverHeight && coverHeight > 200 ? coverHeight : 200;

    if ((navbarTop - scrolltop) <= 0) {
      $mainmenu.removeClass("top");
    } else {
      $mainmenu.addClass("top");
    }

    if (scrolltop >= (coverHeight-navbarHeight)) {

      if(!coverRemoved){

        coverRemoved = true;
        $mainmenu.removeClass('visible');
        setTimeout(function(){
          $mainmenu.removeClass('navbar-cover');
        }, 400);

        lastLowPoint = scrolltop;
      }
      else if (scrolltop > lastScrolltop) {

        lastLowPoint = scrolltop;
        if(scrolltop > lastScrolltop + scrollDownOffset){

          $mainmenu.removeClass('visible');
        }
      }
      else if (lastScrolltop < (lastLowPoint - scrollUpOffset)) {

        lastScrolltop = scrolltop;

        $mainmenu.addClass('visible');
      }
    }
    else {
      $mainmenu.addClass('visible');

      if ($('.cover').length > 0) {
        $mainmenu.addClass('navbar-cover');
        coverRemoved = false;
      }
    }

    if(scrolltop > lastScrolltop + scrollDownOffset || scrolltop < lastLowPoint){
      lastScrolltop = scrolltop;
    }
  }

  function initCollapses(element) {
    $(element).find('.collapse.lock-scroll').each(function () {
      $('[data-target="#' + $(this).attr('id') + '"]').click(function(e) {
        if ($(this).hasClass('collapsed')) {
          $('body, html').addClass('modal-open');
        }
        else {
          $('body, html').removeClass('modal-open');
        }
      });
    });

    // This should work, is bootstrap broken?
    // $(element).find('.collapse.lock-scroll').each(function () {
    //   console.log("FIXME: found lock-scroll");
    //   $(this).on('show.bs.collapse', function() {
    //     console.log("FIXME: show");
    //     $('body').addClass('modal-open');
    //   });
    //   $(this).on('hide.bs.collapse', function() {
    //     $('body').removeClass('modal-open');
    //   });
    // });

    // Toggle the global nav sidebar to slide in
    var $navBtns = $(element).find('.toggle-collapse');
    var $allNavs = $();
    $navBtns.each(function() {
      $allNavs = $allNavs.add($($(this).data('target')))
      $(this).click(function (e) {
        var $btnGroup = $('.toggle-collapse[data-target="' + $(this).data('target') + '"]');
        var $targetNav = $($(this).data('target'));

        if ($targetNav.hasClass('show')) {
          $btnGroup.addClass('collapsed');
          $targetNav.removeClass('show active');
        }
        else {
          $allNavs.removeClass('show active');
          $navBtns.addClass('collapsed');

          $btnGroup.removeClass('collapsed');
          $targetNav.addClass('show active');
        }
      });
    });
  }

  // Return API for other modules
  return {
  };
})(jQuery);
